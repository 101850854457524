<template>
	<div class="promote">
		<van-nav-bar :title="$t('RecuperaroSaldo._RecuperaroSaldo')" left-arrow fixed placeholder @click-left="onClickLeft"
			/>
			<!-- 
				_RecuperaroSaldo:"Recuperar o Saldo",
				_Saldoatual:"Saldo atual",
				_Umcliquepararecuperar:"Um clique para recuperar",
				_yaTeng:"Você só pode recuperar o múltiplo inteiro do equilíbrio (ou seja, semponto decimal)",
				_Tudos:"Tudos",
				_Pesquisadeplataforma:"Pesquisa de plataforma",

				{{ $t('RecuperaroSaldo._Pesquisadeplataforma') }}
			-->
		<div class="xmHlf3AdKNfopQZxGTk7">
			<div class="wPL4o40ajO3bGEmyjpNf">
				<div class="G9JHenpYWEhrwkyqb33R">
					<div class="G9JHenpYWEhrwkyqb33R">
						<p>{{ $t('RecuperaroSaldo._Saldoatual') }}</p>
						<span class="notranslate daV6P29LBSJRZ5JRmzrV"> 0,00</span>
						<svg-icon className="ZaAWGnvFuhPwlBk40od_" icon-class="comm_icon_sx--svgSprite:all"  />
					</div>
					<button type="button" class="ant-btn ant-btn-primary b1QqY3XqFzWf2aPKuhxO"><span>{{ $t('RecuperaroSaldo._Umcliquepararecuperar') }}</span></button>
				</div>
				<div class="uPJRzn7fvKlUm8L_IqQB">{{ $t('RecuperaroSaldo._yaTeng') }}</div>
			</div>
		</div>
		<div class="F_bDHXdsGuld6ItTAhnQ">
			<div class="conter_list">
				<div class="common-tabs">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_" icon-class="icon_dtfl_zh_0--svgSprite:all"  />
					<div class="vi_TBZvr4AMy0V63Nni7 text" style="padding-left: .2rem;"><span>{{ $t('RecuperaroSaldo._Tudos') }}</span></div>
				</div>
			</div>
			<div class="conterright">
				<p style="display: flex;align-items: center;padding-right: .2rem;">
					<searchFor :placeholder="$t('RecuperaroSaldo._Pesquisadeplataforma')"></searchFor>
				</p>
				<van-empty
				    image-size="2.1rem"
				    class="custom-image"
				    :image="img_none_sj"
				    description="Sem dados disponíveis"
				/>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor
		},
		methods:{
			onClickLeft(){
					 this.$router.go(-1);
			},
		}
	}
</script>
<style>
	.promote .van-nav-bar {
		background: var(--theme-main-bg-color);
		border-bottom: .01rem solid var(--theme-color-line);
		color: var(--theme-text-color-darken);
	}

	.promote .van-nav-bar__title {
		color: var(--theme-text-color-darken);
	}

	.promote .van-nav-bar .van-icon {
		color: var(--theme-text-color);
	}

	.promote [class*=van-hairline]::after {
		border-bottom: .01rem solid var(--theme-color-line) !important;
	}

	.promote .van-tabs div:first-child {
		/* height: 100% !important; */
	}

	.promote .van-tabs--line .van-tabs__wrap {
		height: .71rem;
		border-bottom: .01rem solid var(--theme-color-line);
	}

	.promote .van-tab--active {
		font-weight: 400 !important;
	}

	.promote .van-tab__pane {
		-ms-flex: 1;
		flex: 1;
		font-size: .2rem;
		height: calc(100vh - 1.65rem);
		height: calc(var(--vh, 1vh)* 100 - 1.65rem);
		overflow: scroll;
		width: 100%;
	}
	.conterright .van-empty__description{
		font-size: .24rem !important;
	}
</style>


<style scoped>
	.promote {
		background-color: var(--theme-bg-color) !important;
	}
	.xmHlf3AdKNfopQZxGTk7{
		    margin:.2rem;
			color: var(--theme-text-color-lighten);
			font-size: .24rem;
	}
	.xmHlf3AdKNfopQZxGTk7 .wPL4o40ajO3bGEmyjpNf {
	    background-color: var(--theme-main-bg-color);
	    border-radius: .1rem;
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    padding: .25rem .2rem
	}
	.G9JHenpYWEhrwkyqb33R {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: space-between
	}
	 .uPJRzn7fvKlUm8L_IqQB {
	    color: var(--theme-text-color-lighten);
	    font-size: .2rem;
	    margin-top: .1rem;
	    text-align: right;
	    width: 100%
	}
	.daV6P29LBSJRZ5JRmzrV {
	    color: var(--theme-secondary-color-finance);
	    cursor: pointer;
	    font-size: .2rem;
	    margin: 0 .1rem
	}
	.ZaAWGnvFuhPwlBk40od_ {
	    color: var(--theme-primary-color);
	    cursor: pointer;
		height: .24rem;
		width: .24rem;
	    font-size: .24rem
	}
	html .ant-btn.ant-btn-primary{
		 background-color: var(--theme-primary-color);
		    border-color: var(--theme-primary-color)
	}
	.b1QqY3XqFzWf2aPKuhxO {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .18rem;
	    height: .5rem;
	    justify-content: center;
	    margin-left: .2rem;
		font-size: .2rem;
	    padding: 0 .1rem;
		    width: 1.2rem;
	}
	.b1QqY3XqFzWf2aPKuhxO>span {
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    display: -webkit-box;
	    line-height: 1.15;
	    overflow: hidden;
	    text-align: center;
	    text-overflow: ellipsis;
	    vertical-align: middle;
	    white-space: pre-wrap;
	    width: 100%
	}
	.F_bDHXdsGuld6ItTAhnQ{
		padding-left: .2rem;
		display: flex;
		justify-content: space-between;
	}
	.conter_list{
		width: 1.5rem;
	}
	.conterright{
		    margin-left: .2rem;
		    width: 5.4rem;
	}
	.common-tabs{
		background-color:var(--theme-primary-color) ;
		border-radius: .1rem;
		width: 100%;
		display: flex;
		font-weight: 300;
		align-items: center;
		padding: .1rem;
		box-sizing: border-box;
		    color: var(--theme-primary-font-color);
		height: .7rem;
		font-size: .24rem;
	}
	
	.common-tabs .ZaAWGnvFuhPwlBk40od_{
		width: .4rem;
		height: .4rem;
		color: var(--theme-primary-font-color) !important;
	}
</style>
