var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promote"},[_c('van-nav-bar',{attrs:{"title":_vm.$t('withdraw._DetalhesdeRetiradas'),"left-arrow":"","fixed":"","placeholder":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"ant-spin-container"},[_c('section',{staticClass:"qxkFxK6CQByKrJJkH2d4"},[_c('div',{staticClass:"sJE43QCoTSzPBDouVfxI"},[_c('div',{staticClass:"kcTCy9vHkf1H3mdqwzPu",class:{
						I7L5BKKDvfc4LtZ9QV5x:_vm.itemInfo.status<=1,
						Vp8Ab1eEpFjJfeNuKTzI:_vm.itemInfo.status==2,
						mZdNUV1TD6aN7_ngmOtO:_vm.itemInfo.status==3,
					}},[(_vm.itemInfo.status==2)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-1.3rem -13.1057rem","background-size":"22.8171rem 22.2671rem"},style:({
							'background-image':`url(${_vm._coloImg})`
						}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e(),(_vm.itemInfo.status==1)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-3.6rem -13.057rem","background-size":"22.8171rem 22.2671rem"},style:({
									'background-image':`url(${_vm._coloImg})`
								}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e(),(_vm.itemInfo.status==3)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-2.44rem -13.1057rem","background-size":"22.8171rem 22.2671rem"},style:({
									'background-image':`url(${_vm._coloImg})`
								}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e()]),(_vm.itemInfo.status<=1)?_c('p',{staticClass:"EW01JYMCEqV6B9J3qrcI KYYvz9FP2NopeXMcakPR"},[_vm._v("Esperando Retirada ")]):_vm._e(),(_vm.itemInfo.status==2)?_c('p',{staticClass:"EW01JYMCEqV6B9J3qrcI ndJ_zm5K047iicfJJUE1"},[_vm._v("Retirada Bem Sucedida")]):_vm._e(),(_vm.itemInfo.status==3)?_c('p',{staticClass:"EW01JYMCEqV6B9J3qrcI xxTQd0bNMWIQEWt9TUaI"},[_vm._v("Retirada Cancelada")]):_vm._e(),_c('p',{staticClass:"Qzkt5Y1kLOk8zRjHobrG"},[_c('span',[_vm._v("R$ "+_vm._s(_vm.itemInfo.money))])])]),_c('ul',{staticClass:"RHKUHsjbhO3fAAxlEGeg"},[_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('withdraw._Tipodetransação')))]),_c('span',[_vm._v(_vm._s(_vm.itemInfo.account_type))])]),_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('withdraw._MétododeRetirada')))]),_vm._m(0)]),_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('withdraw._HoradeCriação')))]),_c('span',[_vm._v(_vm._s(_vm.itemInfo.create_time))])]),( _vm.itemInfo.status==2 && _vm.itemInfo.update_time)?_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('withdraw._Horadeexecução')))]),_c('span',[_vm._v(_vm._s(_vm.itemInfo.update_time))])]):_vm._e(),_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('withdraw._NúmerodoPedido')))]),_c('span',{staticClass:"lxhhuvR4pdXvlw2qSAS5",on:{"click":function($event){return _vm.copyContent(_vm.itemInfo.osn)}}},[_vm._v(_vm._s(_vm.itemInfo.osn)),_c('span',{staticClass:"PmwtECGo9wfGBMP0uy71 copyIcon VV3ZAK2KjUQCSD0J5Ii4"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","margin-left":".2rem","color":"var(--theme-primary-color)"},attrs:{"icon-class":"comm_icon_copy--svgSprite:all"}})],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"SxjT_FOY1RN3ugh42aHQ"},[_c('span',{staticClass:"EFyusYPjNv2Oi2rPxsqY"},[_c('img',{staticClass:"goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton",staticStyle:{"width":"0.4rem","height":"0.4rem"},attrs:{"src":require("./../../assets/icons/images/icon_normal_pix.png"),"alt":".","data-blur":"0","data-status":"loaded","basescale":"0.8"}})]),_c('span',[_vm._v("PIX")])])
}]

export { render, staticRenderFns }