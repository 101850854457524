<template>
    <div class="maxMaxth evebt">
        <div class="cRKA8AT8lf2bItsMSztA">
            <div class="a7neOLNNGzlPwu32tGO4">
                <div class="RnNfgaFEYzhMdHzQIun2">
					<span> {{ $t('_VIP._Bônus') }}</span>
					<span class="XGEOwGXgDL9a2SebPqvj"> 0,00</span>
				</div>
            </div>
            
        </div>
		<!-- img_none_jl.webp -->
        <van-empty
            image-size="2.1rem"
            class="custom-image"
            :image="img_none_sj"
            :description="$t('description')"
        />
     </div>
  </template>
  
  <script>

  
  
  
  export default {
   name: 'canReceive',
   components: {

   },
   data(){
     return {
		  value1: 0,
	   option1: [
		 { text: '全部商品', value: 0 },
		 { text: '新款商品', value: 1 },
		 { text: '活动商品', value: 2 },
	   ],
     }
   },
   methods:{
     tabFun(e){
       this.$store.commit('active',e)
     },
     stickyScroLL(e){
     }
   }
  }
  </script>
  <style>
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-menu__bar{
		background-color: transparent;
		box-shadow: none;
		height: .5rem;
		
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-menu__bar .van-dropdown-menu__item{
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .5rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .5rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		color: var(--theme-text-color-lighten) !important;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-menu__bar .van-dropdown-menu__item .van-dropdown-menu__title{
		font-size: .2rem;
		color: var(--theme-text-color-lighten) !important;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-menu__title::after{
		
		border-color: transparent transparent var(--theme-text-color-lighten) var(--theme-text-color-lighten);
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-popup,.v0FZl0YCkbZ5KOXk68M4 .van-cell{
			background-color: transparent;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-popup--top{
		width: auto;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-overlay {
		background-color:transparent;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-item{
		position: absolute;
		min-width: 1rem;
		left: 0;
		width:3.15rem;
		top: .6rem !important;
		transition: .3s;
		height: 400px;
		/* overflow: initial; */
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-popup{
		    background-color: var(--theme-main-bg-color) !important;
		    border: .01rem solid var(--theme-color-line) !important;
		    border-radius: .1rem;
		    box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		    box-sizing: border-box;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-cell{
		    font-size: .24rem;
		    line-height: .8rem;
		    min-height: .8rem;
		    padding: 0 .2rem;
			color: var(--theme-text-color-lighten);
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-item__option--active{
		color: var(--theme-primary-color);
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-cell__value{
		display: none;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-cell::after{
		border-bottom:none;
		color: var(--theme-text-color-lighten);
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-dropdown-menu{
		position: relative;
	}
	.v0FZl0YCkbZ5KOXk68M4 .van-cell__title{
		
		
		white-space: nowrap;
	}
</style>

  <style scoped>
  
  .v0FZl0YCkbZ5KOXk68M4{
	  display: flex;
	  
  }
    .cRKA8AT8lf2bItsMSztA{
        height: .9rem;
        padding: 0 .2rem;
    }
    .a7neOLNNGzlPwu32tGO4 {
    -ms-flex-align: center;
    -ms-flex-pack: end;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: .5rem;
    height: .9rem;
	justify-content: flex-end;
}
     .cRKA8AT8lf2bItsMSztA {
    display: -ms-flexbox;
    font-size: .24rem;
    line-height: 1.5;
        color: var(--theme-text-color-lighten);
}

.XGEOwGXgDL9a2SebPqvj {
    color: var(--theme-secondary-color-finance)
}

  </style>
  