<template>
	<div class="promote">
		<van-nav-bar :title="$t('withdraw._Detalhesdatarefadeapostas')" left-arrow fixed placeholder @click-left="onClickLeft" />
		
		<div class="ant-spin-container">
			<ul class="AjRgIdSLYHKFEBOEpRaU">
				<li><span>{{ $t('withdraw._Tipodefonte') }}</span><span class="kpji6UF9r2OZ7psODmfu">
					<svg-icon className="tenSlis" icon-class="icon_ly_cz--svgSprite:all" />
					{{ $t('withdraw._Depósito') }}</span></li>
				<li><span>{{ $t('withdraw._HoradeCriação') }}</span><span class="b8hK_QyUvWX4E1lOf1Io">{{itemInfo.create_time}}</span></li>
				<li><span>{{ $t('withdraw._ObterAdicionarpontos') }}</span><span class="gGRUpASJoGeiUgGIfTQg EyDAtAgZNxuH51G8CRpZ">+{{itemInfo.money}}</span></li>
				<li><span>{{ $t('withdraw._Exigirmultiplicadordeaposta') }}</span><span class="jx432ohmKbekjvVt5nnw">{{itemInfo.multiple}}</span></li>
				<li><span>{{ $t('withdraw._Apostaválidanecessária') }}</span><span>{{itemInfo.target_bet}}</span></li>
				<li><span>{{ $t('withdraw._Apostajáválida') }}</span><span class="EyDAtAgZNxuH51G8CRpZ">{{itemInfo.valid_bet}}</span></li>
				<li><span>{{ $t('withdraw._Vocêprecisaapostar') }}</span><span>{{itemInfo.need_bet}}</span></li>
				<li><span>{{ $t('withdraw._Statusdatarefa') }}</span>
					<!-- <span class="txOVmtyvnkxdka0hQGrt">{{itemInfo.status}}</span>
					<span class="txOVmtyvnkxdka0hQGrt">{{itemInfo.status}}</span>
					<span class="txOVmtyvnkxdka0hQGrt">{{itemInfo.status}}</span>
					<span class="txOVmtyvnkxdka0hQGrt">{{itemInfo.status}}</span>
					<span class="txOVmtyvnkxdka0hQGrt">{{itemInfo.status}}</span> -->
					<span class="ksVo8zGmQjOXnmPM1AMA k2_x6DM77ydgJiNbf_dp" v-if="itemInfo.status=='1'" >
						Aguardando apostas
					</span>
					<span class="pRRvAqiB8XjLf1Zi2vZR" v-if="itemInfo.status=='2'" >
						Têm em mão
					</span>
					<span class="mwx4K1DUI_ZKHLPGQNUp" v-if="itemInfo.status=='3'" >
						Completado
					</span>
					<span class="d0eJbdoCsvr1QKEtJMmt" v-if="itemInfo.status=='4'" >
						Abandonado
					</span>
				</li>
				<li v-if="itemInfo.status==3"><span>{{ $t('withdraw._TempodeConclusão') }}</span><span>{{itemInfo.complete_time}}</span></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				itemInfo:{}
			}
		},
		mounted() {
			console.log(this.$route.query,'item')
			let {content} = this.$route.query;
			if(content){
				this.itemInfo=JSON.parse(content)
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped>
	.ant-spin-container{
		padding: .2rem;
	}
	.AjRgIdSLYHKFEBOEpRaU{
		background-color: var(--theme-main-bg-color);
		    border-radius: .1rem;
			    padding: 0 .2rem;
	}
	.AjRgIdSLYHKFEBOEpRaU li{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: .2rem 0;
		    border-bottom: .01rem solid var(--theme-color-line);
	}
	.AjRgIdSLYHKFEBOEpRaU>li span:first-child {
	    color: var(--theme-text-color-lighten);
	    max-width: 40%;
		word-break: break-word;
		    font-size: .24rem;
	}
	.AjRgIdSLYHKFEBOEpRaU>li span:last-child {
	    color: var(--theme-text-color-darken);
	    max-width: 58%;
	    text-align: right;
		font-size: .24rem;
	    white-space: pre-wrap;
	    word-break: break-all
	}
	.AjRgIdSLYHKFEBOEpRaU li .jx432ohmKbekjvVt5nnw,.AjRgIdSLYHKFEBOEpRaU li .b8hK_QyUvWX4E1lOf1Io{
		color: var(--theme-text-color-lighten) !important;
	}
	.AjRgIdSLYHKFEBOEpRaU>li .gGRUpASJoGeiUgGIfTQg {
	    color: var(--theme-secondary-color-finance)!important
	}
	.txOVmtyvnkxdka0hQGrt {
	    color: var(--theme-secondary-color-success)!important;
	    font-weight: 700
	}
	.tenSlis {
		    color: var(--theme-text-color-lighten);
		    font-size: .3rem;
			height: .3rem;
			width: .3rem;
	}
	.d0eJbdoCsvr1QKEtJMmt {
	    /* color: var(--theme-text-color-darken)!important */
		color: var(--theme-secondary-color-error) !important;
	}
	
	.pRRvAqiB8XjLf1Zi2vZR {
	    color: var(--theme-secondary-color-finance)!important
	}
	
	.mwx4K1DUI_ZKHLPGQNUp {
	    color: var(--theme-secondary-color-success)!important
	}
</style>
