<template>
	<div class="promote">
		<van-nav-bar title="Segurança" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="cAoDSW0NOMzVCs7RQTki">
			<div>
				<div class="rHSj0qrmy7hccITxL6X_ boxShadow theme-box-shadow-h5">
					<div class="orMI_wYkbNnS7_FUTUNf">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i F2UYjPylKq8u8Wg74k2A"  icon-class="input_icon_zh--svgSprite:all" />
							<span>Nome de Usuário</span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="gqGtHUKfV3ko7_avEsGn"> <span>{{userInfo.user_name}}</span></div>
						</div>
					</div>
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi">
						<div class="yujDwPoiD5UMvwPBxKm2">
						<svg-icon className="i TZM0zF2XLvNjre5VhX26"  icon-class="input_icon_sj--svgSprite:all" />
						<span><span id="">Nº de Telefone</span></span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="gqGtHUKfV3ko7_avEsGn"> <span>Desvincular</span></div>
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i TZM0zF2XLvNjre5VhX26"  icon-class="input_icon_yj--svgSprite:all" />
							<span><span id="">Endereço de e-mail</span></span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="gqGtHUKfV3ko7_avEsGn"> <span>Desvincular</span></div>
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i "  icon-class="icon_wd_ggyz_b--svgSprite:all" />
							<span><span id="">Google Authenticator</span></span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="gqGtHUKfV3ko7_avEsGn"> <span>Desvincular</span></div>
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
				</div>
				<!--  -->
				<div class="rHSj0qrmy7hccITxL6X_ boxShadow theme-box-shadow-h5">
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi" @click="openUrl('SenhadeLogin')">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i g7CuuAmR8nZZdgZgSN1k"  icon-class="input_icon_yz--svgSprite:all" />
							<span>Senha de Login</span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
					<!-- Você precisa estabelecer uma senha de retirada primeiro
					 openUrl('security')
					 userInfo.set_withdraw_password
					 -->
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi" @click="openConterArr">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i FqXYiOWjKM502dGV_yaO"  icon-class="input_icon_card--svgSprite:all" />
							<span><span id="">Senha de Saque</span></span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<!--  -->
							<div class="gqGtHUKfV3ko7_avEsGn" v-if="userInfo.set_withdraw_password"> <span>Foi Definido</span></div>
							<div class="gqGtHUKfV3ko7_avEsGn" v-if="!userInfo.set_withdraw_password"> <span>Não Configurado</span></div>
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
					<div class="orMI_wYkbNnS7_FUTUNf D0DrdrtGT6739ZXZUTNi">
						<div class="yujDwPoiD5UMvwPBxKm2">
							<svg-icon className="i g7CuuAmR8nZZdgZgSN1k"  icon-class="input_icon_mb--svgSprite:all" />
							<span>Pergunta de Segurança</span></div>
						<div class="ObBOMsqRadLIgW2UpcTL">
							<div class="gqGtHUKfV3ko7_avEsGn"> <span>Não Configurado</span></div>
							<div class="ROg3BL67rLVBJahX0KeJ">
								<svg-icon className="i_c"  icon-class="comm_icon_fh--svgSprite:all" />
							</div>
						</div>
					</div>
				</div>
				<div class="rHSj0qrmy7hccITxL6X_ boxShadow theme-box-shadow-h5"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { Toast } from 'vant';
	export default {
		computed:{
			...mapState(['popList','userInfo','moneyInfo'])
		},
		methods:{
			onClickLeft(){
				this.$router.go(-1);
			},
			openConterArr(e){
				e.stopPropagation();
				if(!this.userInfo.set_withdraw_password){
					Toast.fail({
						message:this.myLineBreaks('Você precisa estabelecer uma senha de retirada primeiro'),
						duration:1000
					});
					return false;
				}else{
					this.openUrl('security')
				}
				// Você precisa estabelecer uma senha de retirada primeiro
				//  openUrl('security')
				//  userInfo.set_withdraw_password
			},
			// security
		}
	}
</script>

<style scoped>
	.cAoDSW0NOMzVCs7RQTki {
	    padding: .2rem;
	}
	.rHSj0qrmy7hccITxL6X_ {
	    background-color: var(--theme-main-bg-color);
	    border-radius: .14rem;
	    -webkit-box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
	    box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
	    margin-bottom: .2rem;
	    padding: 0 .2rem
	}
	
	.rHSj0qrmy7hccITxL6X_:last-child {
	    margin-bottom: 0
	}
	
	.rHSj0qrmy7hccITxL6X_ .KnwC9EZMD7RzoNaNZY3B .tool-tips-box {
	    color: #ff0
	}
	.orMI_wYkbNnS7_FUTUNf .i{
		width: .25rem !important;
		height: .25rem !important;
	}
	.g7CuuAmR8nZZdgZgSN1k {
	    color: #04be02;
	}
	.F2UYjPylKq8u8Wg74k2A{
		    color: var(--theme-text-color-lighten);
	}
	.TZM0zF2XLvNjre5VhX26{
		    color: var(--theme-primary-color);
	}
	.rHSj0qrmy7hccITxL6X_ .orMI_wYkbNnS7_FUTUNf {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    border-bottom: .01rem solid var(--theme-color-line);
	    display: -ms-flexbox;
	    display: flex;
	    height: .8rem;
	    justify-content: space-between;
	    padding-left: .04rem;
	    position: relative
	}
	
	.rHSj0qrmy7hccITxL6X_ .orMI_wYkbNnS7_FUTUNf.D0DrdrtGT6739ZXZUTNi {
	    cursor: pointer
	}
	
	.rHSj0qrmy7hccITxL6X_ .M2BR1cuBVDf__A8Nfe5i {
	    display: inline-block;
	    margin-top: .08rem;
	    overflow: hidden;
	    text-align: right;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 1.6rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .zQWHdNDiE4KyGqQgbI3d {
	    margin-top: .04rem
	}
	
	.rHSj0qrmy7hccITxL6X_>div:last-child {
	    border-bottom: none
	}
	
	.rHSj0qrmy7hccITxL6X_ .yujDwPoiD5UMvwPBxKm2 {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.rHSj0qrmy7hccITxL6X_ .yujDwPoiD5UMvwPBxKm2 i,.rHSj0qrmy7hccITxL6X_ .yujDwPoiD5UMvwPBxKm2 svg {
	    height: .2rem;
	    width: .2rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .yujDwPoiD5UMvwPBxKm2 span {
	    color: var(--theme-text-color-darken);
	    font-size: .22rem;
	    margin-left: .16rem
	}
	.FqXYiOWjKM502dGV_yaO {
	    color: #e94d3c;
	}
	.rHSj0qrmy7hccITxL6X_ .yujDwPoiD5UMvwPBxKm2 span span {
	    margin-left: 0
	}
	
	.rHSj0qrmy7hccITxL6X_ .lYKpVyd_C2lxIv9oVa1q i {
	    margin-top: .01rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .ObBOMsqRadLIgW2UpcTL,.rHSj0qrmy7hccITxL6X_ .lYKpVyd_C2lxIv9oVa1q i {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.rHSj0qrmy7hccITxL6X_ .ObBOMsqRadLIgW2UpcTL .gqGtHUKfV3ko7_avEsGn {
	    color: var(--theme-text-color-lighten);
	    font-size: .22rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .ObBOMsqRadLIgW2UpcTL .ROg3BL67rLVBJahX0KeJ {
	    margin-left: .2rem;
	    position: relative;
	    top: -.20rem
	}
	.ROg3BL67rLVBJahX0KeJ .i_c{
		    transform: rotate(180deg) !important;
			position: absolute;
			right: 0;
			top: 0;
	}
	
	.rHSj0qrmy7hccITxL6X_ .ObBOMsqRadLIgW2UpcTL .ROg3BL67rLVBJahX0KeJ i,.rHSj0qrmy7hccITxL6X_ .ObBOMsqRadLIgW2UpcTL .ROg3BL67rLVBJahX0KeJ svg {
	    color: var(--theme-color-line);
	    height: .24rem!important;
	    margin-right: -.055rem;
	    margin-top: .1rem;
	    -webkit-transform: rotate(90deg);
	    transform: rotate(90deg);
	    width: .15rem!important
	}
	
	.rHSj0qrmy7hccITxL6X_ .TM8jwlqpn4KLUmrFcwJs {
	    -ms-flex-align: center;
	    align-items: center;
	    color: var(--theme-text-color-lighten);
	    display: -ms-flexbox;
	    display: flex
	}
	
	.rHSj0qrmy7hccITxL6X_ .TM8jwlqpn4KLUmrFcwJs span {
	    margin-right: .117rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .TM8jwlqpn4KLUmrFcwJs i,.rHSj0qrmy7hccITxL6X_ .TM8jwlqpn4KLUmrFcwJs svg {
	    color: var(--theme-primary-color);
	    height: .196rem;
	    width: .233rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .OWU0InjuNwSET267o0t_ {
	    color: var(--theme-text-color-darken);
	    margin-right: .07rem
	}
	
	.rHSj0qrmy7hccITxL6X_ .uHoC9mmSi8rMSpL17UHV {
	    color: var(--theme-secondary-color-success)
	}
	
	.rHSj0qrmy7hccITxL6X_ .Yf6MkqHzQoStKQy5iSQW,.rHSj0qrmy7hccITxL6X_ .satxrYA8z4bPs1jEBb_z {
	    color: var(--theme-text-color-lighten)
	}
</style>
