import { render, staticRenderFns } from "./Conta.vue?vue&type=template&id=35313dac&scoped=true"
import script from "./Conta.vue?vue&type=script&lang=js"
export * from "./Conta.vue?vue&type=script&lang=js"
import style1 from "./Conta.vue?vue&type=style&index=1&id=35313dac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35313dac",
  null
  
)

export default component.exports