<template>
	<div class="c-s z-ant-input" v-if="isShow" style="position: fixed;top: 0;height: 100vh;z-index: 999;">
		<van-overlay :show="isShow" @click="_click(false) " style="position: initial;" z-index="4" >
			 <div class="wrapper" >
				<div style="">
					<div class="ant-modal-content" @click.stop>
							<div class="ant-modal-header"><div id="rcDialogTitle1" class="ant-modal-title">{{ $t('withdraw._AdicionarPIX') }}</div></div>
							<div class="ant-modal-body">
								<div class="gD0G2pAAJZZTJ1n0KQXv" >
									<!-- margin-bottom: .5rem; -->
									<antInput  :clearable='false' @onInput="_onInput" :modelValue="forData.account_name" form-key='account_name'
										placeholder="Introduza o seu nome real">
										<div slot="left"
											style="color: var(--theme-text-color-darken);font-size:.18rem;display: flex;align-items: center;">
											<svg-icon className="prefix"
												icon-class="input_icon_zsxm--svgSprite:all" />
										</div>
									</antInput>
								</div>
								<div class="gD0G2pAAJZZTJ1n0KQXv">
									<antS @_click="_click_antS" :dados="['CPF','PHONE','EMAIL','EVP']" :modelvalue="modelvalue">
										<div slot="left-icon"
										style="color: var(--theme-text-color-darken);font-size:.18rem;display: flex;align-items: center;"
										>
											<svg-icon className="prefix"
												icon-class="input_icon_zh--svgSprite:all" />
										</div>
									</antS>
								</div>
								<div class="gD0G2pAAJZZTJ1n0KQXv" v-if="isPxl">
									<antInput :clearable='false' @onInput="_onInput" :modelValue="forData.account_pix" form-key='account_pix'
										placeholder="Introduza a sua chave do PIX">
										<div slot="left"
											style="color: var(--theme-text-color-darken);font-size:.18rem;display: flex;align-items: center;">
											<svg-icon className="prefix"
												icon-class="pix_icon--svgSprite:all" />
										</div>
									</antInput>
								</div>
								<div class="gD0G2pAAJZZTJ1n0KQXv">
									<antInput maxlength="14" :modelValue="forData.account_cpf" @onInput="_onInput" :clearable='false' form-key='account_cpf'
										placeholder="Introduza o número de 11 dígitos do CPF">
										<div slot="left"
											style="color: var(--theme-text-color-darken);font-size:.18rem;display: flex;align-items: center;">
											<svg-icon className="prefix"
												icon-class="input_icon_cpf--svgSprite:all" />
										</div>
									</antInput>
								</div>
								<div class="Kw58s_FCuipHRV_EW4tG">
									<span>{{ $t('withdraw._Verifique') }} </span>
								</div>
								<div class="Pt3rGlTmq0_WLpTGHsA_" @click="setBank">
									<van-button class="JHBAn8W0jkHI1OM_X2fy" type="primary">{{ $t('withdraw._Confirmar') }}</van-button>
								</div>
							</div>
					</div>
				</div>
				
			</div>
		</van-overlay>
		
	</div>
</template>

<script>
	import {
		setBank
	} from "@/apis/modules/login.js"
	import antInput from "./input.vue"
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import antS from "./ant-s.vue"
	import { Toast } from 'vant';
	export default{
		components:{
			antInputPassword,
			antInput,
			antS
		},
		props:{
			isShow:{
				type:Boolean,
				default: false,
			},
			formDta:{
				type:[Object],
				default:()=>{return {}},
				
			}
			
		},
		data() {
			return{
				show:true,
				isShowNum:false,
				shuruKOangName:'',
				modelvalue:0,
				isPxl:false,
				forData:{
					account_name:'',
					account_pix:'',
					account_cpf:'',
					account_type:'CPF',
				}
			}
		},
		watch: {
		    // 当message变化时，这个函数就会被调用
		    isShow(newValue, oldValue) {
				this.forData = this.formDta.account_type ? {...this.formDta} : {account_name:'',account_pix:'',account_cpf:'',account_type:'CPF',};
				if(this.forData.account_type){
					this.modelvalue=['CPF','PHONE','EMAIL','EVP'].indexOf(this.forData.account_type);
					if(this.modelvalue==-1){
						this.modelvalue=0;
					}
				}
				this.isPxl=false;
				if(this.forData.account_type=='EMAIL'||this.forData.account_type=='EVP' ||this.forData.account_type=='PHONE'){
					this.isPxl=true;
				}
				this.forData.account_cpf = this.myFormatNum(this.forData.account_cpf);
				
		  //     console.log(this.forData,newValue);
		      // 这里可以添加更多响应数据变化的逻辑
		    }
		  },
		methods:{
			_click(){
				this.$emit('_click',false)
			},
			_click_antS(item,index){
				this.modelvalue=index;
				this.forData.account_type=item;
				this.isPxl=false;
				if(item=='EMAIL'||item=='EVP' ||item=='PHONE'){
					this.isPxl=true;
				}
			},
			myFormatNum(num,oldNum){
				let oldLength = oldNum ? oldNum.length : num.length;
				if(num.length==3 && oldLength > num.length){
					num = num.slice(0,2);
				}
				if(num.length==7 && oldLength > num.length){
					num = num.slice(0,6);
				}
				if(num.length==11 && oldLength > num.length){
					num = num.slice(0,10);
				}
				num = num.replace(/[^0-9]/g, '');
				if(num.length>=3 && num[3] != '.'){
					num = num.slice(0,3)+'.'+num.slice(3);
				}
				if(num.length>=7 && num[7] != '.'){
					num = num.slice(0,7)+'.'+num.slice(7);
				}
				if(num.length>=11 && num[11] != '-'){
					num = num.slice(0,11)+'-'+num.slice(11);
				}
				return num;
			},
			_onInput(v,k){
				if(k == 'account_cpf'){
					v = this.myFormatNum(v,this.forData[k])
				}
				this.forData[k]=v;
			},
			setBank(){
				let msg = '';
				if(!this.forData.account_name)msg = 'O campo de nome real não pode ficar vazio';
				if(!msg && this.modelvalue > 0 && !this.forData.account_pix){msg = 'A conta PIX não pode ficar vazia';}
				let accountCpf = this.forData.account_cpf ? this.forData.account_cpf.replace(/\D/g, '') : 0;
				if(!msg && (!accountCpf || accountCpf.length != 11)){msg = 'Introduza o número de 11 dígitos do CPF';}
				if(msg){
					Toast.fail({
						message:this.myLineBreaks(msg),
						duration:0
					});
					setTimeout(()=>{
						Toast.clear()
					},1000)
					return false;
				}

				setBank({...this.forData}).then(({code,msg,data})=>{
					if(code==0){
						Toast.success({
							message:this.myLineBreaks(msg),
							duration:1000
						});
						setTimeout(()=>{
							location.reload();
							this.$attrs.getBank();
							this.$emit('onIsShow',false)
						},1500)
						return false;
					}
					Toast.loading({
						message:this.myLineBreaks(msg),
						duration:1000
					});
				})
			}
		}
	}
</script>
<style>
/* 	.c-s{
		width: 100%;
		height: 100vh;
	} */
	.c-s{
		width: 100vw;
	}
	.c-s .van-overlay{
		width: 100vw;
		/* position: absolute;
		    background-color: rgba(0, 0, 0, .45); */
	}
	
</style>
<style scoped>
	.wrapper{
		 display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}
	 .ant-modal-content {
	    background-color: var(--theme-main-bg-color);
	    border-width: thin;
	    border-style: solid;
	    border-image: initial;
		box-sizing: border-box;
		width: 6.9rem;
	    border-color: var(--theme-color-line);
	    border-radius: 0.2rem;
	    max-height: calc(var(--vh, 1vh)*100 - .1rem);
	    overflow: auto;
	    position: static;
		margin: 0 auto;
	}
	.gD0G2pAAJZZTJ1n0KQXv{
		margin-bottom: .5rem;
	}
	.ant-modal-header{
		 background-color: var(--theme-main-bg-color);
		    border-bottom: none;
		    border-radius: .2rem .2rem 0 0;
		    padding: .2rem .3rem
	}
	.ant-modal-title{
			color: var(--theme-text-color-darken);
		    font-size: .24rem;
		    font-weight: revert;
		    line-height: .34rem;
		    text-align: center;
	}
	.Ou1aviPriFX421w97dGA{
		display: flex;
		align-items: center;
		justify-content: space-between;
		    margin-bottom: .15rem;
		    font-size: .22rem;
			    color: var(--theme-text-color-darken);
	}
	.Ou1aviPriFX421w97dGA .ZaAWGnvFuhPwlBk40od_{
		font-size: .36rem;
		width: .36rem;
		height: .36rem;
		    color: var(--theme-text-color-lighten);
	}
	.Ou1aviPriFX421w97dGA .isShowNum .ZaAWGnvFuhPwlBk40od_{
		color: var(--theme-primary-color);
	}
	.ant-modal-body{
		    padding-top: .2rem !important;
		    padding: .3rem;
	}
	.Kw58s_FCuipHRV_EW4tG{
		    color: var(--theme-text-color-darken);
		    font-size: .22rem;
			display: flex;
	}
	._7B8oTwzAn2VmdNYw3F0z {
	    color: var(--theme-primary-color);
	    cursor: pointer;
	    text-align: right;
	}
	.closeIcon{
		
	}
	.QzRd3YqTnYxG8Latq8fW {
	    background: none;
	    height: .64rem;
	    width: .64rem;
		margin: 0 auto;
	}
	.QzRd3YqTnYxG8Latq8fW img{
		width: 100%;
		height: 100%;
	}
	.JHBAn8W0jkHI1OM_X2fy {
	    border-radius: .14rem;
	    font-size: .24rem;
		width: 100%;
	    height: .7rem;
		 background-color: var(--theme-primary-color);
		border-color: var(--theme-primary-color);
	    line-height: .7rem;
	}
</style>