<template>
    <div class="maxMaxth promote">
		<van-nav-bar
		  title="Convidar"
		  left-arrow
		  fixed
		  placeholder
		  @click-left="onClickLeft"
		/>
		<van-tabs v-model="activeName"
		@click="routerFun" 
		:lineWidth="(activeLength*6+'px')"
		animated
		line-height='1px'
		sticky
		offset-top=".9rem"
		background="var(--theme-main-bg-color)" 
		:ellipsis="false"
		title-active-color="var(--theme-primary-color)"
		title-inactive-color="var(--theme-text-color-darken)"
		color="var(--theme-primary-color)">
			<van-tab  title-style="font-size: 0.22rem;" v-for="(item,index) in tabList"  :title="$t(item.name)" :name='index' :key="index" >
				<component @tabModel="tabModel" :activeName.sync="activeName" :is="item.content"></component>
			</van-tab>
		</van-tabs>
		 <Direto  :isShow='true'></Direto>
		 <DetalhesdaAposta :isShow='true'></DetalhesdaAposta>
     </div>
  </template>
  
  <script>
	import LinkdeConvite from "./LinkdeConvite.vue"
	import MeusDados from "./MeusDados.vue"
	import TodosOsDados from "./TodosOsDados.vue"
	import Desempenho from "./Desempenho.vue"
	import Comisso from "./Comissão.vue"
	import DadosdoSubordinado from "./DadosdoSubordinado.vue"
	import ApostasdosSubordinados from "./ApostasdosSubordinados.vue"
	import CadastrarSubordinados from "./CadastrarSubordinados.vue"
	import TaxadeComissao from "./TaxadeComissao.vue"
	import { mapState } from 'vuex';
	import Direto from "./components/Direto.vue";
	import DetalhesdaAposta from "./components/DetalhesdaAposta.vue";
	
  export default {
   name: 'vip',
   components: {
		LinkdeConvite,
		Direto,
		DetalhesdaAposta,
   },
   computed:{
   	...mapState(['popList','userInfo'])
   },
   data(){
     return {
        activeName:0,
        activeLength:25,
		share:{
			_LinkdeConvite:"Link de Convite",
			_MeusDados:"Meus Dados",
			_TodososDados:"Todos os Dados",
			_Desempenho:"Desempenho",
			_Comissão:"Comissão",
			_DadosdoSubordinado:"Dados do Subordinado",
			_ApostasdosSubordinados:"Apostas dos Subordinados",
			_TaxadeComissão:"Taxa de Comissão",
			_CadastrarSubordinados:"Cadastrar Subordinados",
		},
        tabList:[
                {
                    name:'share._LinkdeConvite',
					content:LinkdeConvite
                },
                {
                    name:'share._MeusDados',
					content:MeusDados,
                },
                {
                    name:'share._TodososDados',
                    content:TodosOsDados
                },
                {
                    name:'share._Desempenho',
                    content:Desempenho
                },
				{
				    name:'share._Comissão',
				    content:Comisso,
				},
				{
				    name:'share._DadosdoSubordinado',
				    content:DadosdoSubordinado,
					// DadosdoSubordinado
				},
				{
				    name:'share._ApostasdosSubordinados',
					content:ApostasdosSubordinados
				},
				{
				    name:'share._TaxadeComissão',
				    content:TaxadeComissao,
					//ApostasdosSubordinados
				},
                {
                    name:'share._CadastrarSubordinados',
                    content:CadastrarSubordinados
                }
            ]
     }
   },
   methods:{
     tabFun(e){
       this.$store.commit('active',e)
     },
	 tabModel(e,l){
		 this.activeName=e;
	 },
     routerFun(e,l){
        this.activeLength=l.length;
     },
	 onClickLeft(){
	 		 this.$router.go(-1);
	 },
   }
  }
  
  </script>
  <style>
	  .promote .van-nav-bar{
		      background: var(--theme-main-bg-color);
		          border-bottom: .01rem solid var(--theme-color-line);
			    color: var(--theme-text-color-darken);
	  }
	  .promote .van-nav-bar__title{
		  color: var(--theme-text-color-darken);
	  }
	  .promote .van-nav-bar .van-icon{
		      color: var(--theme-text-color);
	  }
	  .promote [class*=van-hairline]::after{
			border-bottom: .01rem solid var(--theme-color-line) !important;
	  }
	  .promote .van-tabs div:first-child{
		  /* height: 100% !important; */
	  }
	  .promote .van-tabs--line .van-tabs__wrap{
		  height: .71rem;
		  border-bottom: .01rem solid var(--theme-color-line);
	  }
	  .promote .van-tab--active {
	      font-weight: 400 !important;
	  }
	  .promote .van-tab__pane {
	      -ms-flex: 1;
	      flex: 1;
	      font-size: .2rem;
	      height: calc(100vh - 1.65rem);
	      height: calc(var(--vh, 1vh)* 100 - 1.65rem);
	      overflow: scroll;
	      width: 100%;
	  }
	  .nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck{
		  box-sizing: border-box;
	  }
	  .currencyAmount.active{
	  	 border-bottom: .01rem solid var(--theme-primary-color);
	  	    color: var(--theme-primary-color) !important;
	  	    cursor: pointer;
	  	    padding: 0 0 .02rem
	  }
  </style>
  <style scoped>
	.promote{
		    background-color: var(--theme-bg-color) !important;
	}
  
  </style>
  