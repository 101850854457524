<template>
	<div class="">
		<div class="ZjZR4FiYzgfJQbE3_Etq">
			<datetimePicker class="ifzwCQu8BPs2c24nZdzQ" @_timeOnFunEndDate="_timeOnFunEndDate"></datetimePicker>
			<div class="">
				<searchFor @search="_search" @input="_searchInput" :placeholder="$t('promote._IDdeMembro')"></searchFor>
			</div>
		</div>
		<div class="TBtGFT5INIot5sEUIm8w vantDataList">
			<van-list v-if="fromList && fromList.length" :finished="finished" v-model="loading"   @load="onLoad" >
				<div slot="default" v-for="(item,index) in fromList"  :key="index" :title="item">
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<div class="Iu2XDEUxSXVTmZ2ClDl5">
								<div class="s-s" @click="copyContent(item.id)">
									{{item.id}}
									<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
								</div>
								<div class="dG0FpGdkHU5MgpFCLljd globalVipLevel">
									<p class="TmCsEwuUw4tM7cplmM6W">{{item.vip}}</p>
								</div>
							</div>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._ApostasVálidas')}}</label><span class="currencyAmount">
								{{item.bet_total}}</span>
						</van-col>
					</van-row>
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<span class="dateTime">{{item.reg_time}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('_VIP._Depósito') }}</label>
							<span class="currencyAmount">
								{{item.recharge_total}}
							</span>
						</van-col>
					</van-row>
				</div>
			</van-list>
			<van-empty image-size="2.1rem" v-if="!fromList || !fromList.length" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
			<div class="nVAOZ5L2ZIX7FtNLSqAD" v-if="showTotal" > 
				<div class="PzFW1R_NxciR5KCVuKck "
					 :class="{
						 kAaqfTc5guezIg4i8OHU:!isTanzhans
					 }"
				>
					<!-- 
				
				
					 
					-->

					<div class="L7qtNTcvxkLyv8NTsUx8" @click="isTanzhans=!isTanzhans">
						<!-- ED6sIMJuJEuPpvzsMw30 -->
						<svg-icon class="dPPYvWfEoY8BG3Qg86dU " 
						  :class="{
						  	ED6sIMJuJEuPpvzsMw30:!isTanzhans
						  }"
						  icon-class="img_jt_ss--svgSprite:all" />
					</div>
					<van-row type="flex" class="WKP0P703NvX8zzUuEJg9" justify="space-between">
						<van-col span="12">
							<label>{{$t('promote._RecargaDireta')}}</label>
							<span class="currencyAmount">{{shareInfo.n1_recharge_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._Primeirarecargadireta')}}</label>
							<div class="customNormal"><span> {{shareInfo.n1_recharge_num}}</span></div>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._OutroDepósito')}}</label>
							<span class="currencyAmount">{{shareInfo.n2_recharge_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{  $t('promote._Outrasrecargasiniciais')}}</label>
							<div class="customNormal"><span> {{shareInfo.n2_recharge_num}}</span></div>
						</van-col>
						<van-col span="12">
							<label>{{  $t('promote._DepósitoTotal')}}</label>
							<span class="currencyAmount">{{shareInfo.all_recharge_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._Totaldejogadoresfazendoprimeirosdepósitos')}}</label>
							<div class="customNormal"><span> {{shareInfo.all_recharge_num}}</span></div>
						</van-col>
					</van-row>
					
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	// allData
	import {
		shareAllData
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor
		},

		data() {
			return {
				list: [],
				isTanzhans:false,
				loading: false,
				finished: false,
				shareInfo: {},
				shareTime: 'Ontem',
				active: 0,
				fromList:[],
				showTotal: false,
				fromType:1,
				formData: {
					start_time: '',
					end_time: '',
					search_id: '', //搜索下级ID
				}
			}
		},
		mounted() {
			this.shareAllData();
		},
		methods: {
			_timeOnFunEndDate(Tim, anD) {
				// console.log(Tim,anD)
				this.fromList=[];
				this.finished = false;
				this.fromType=1;
				this.formData.start_time = Tim;
				this.formData.end_time = anD;
				this.shareAllData();
			},
			_search(e) {
				this.formData.search_id = e;
				this.shareAllData();
			},
			_searchInput(e){
				this.formData.search_id = e.target.value;
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				
				this.fromType=this.fromType+1;
				this.shareAllData();
			},
			shareAllData() {
				shareAllData({
					...this.formData,
					page:this.fromType,
				}).then(({
					data
				}) => {
					
					let {list}=data;
					if(JSON.stringify(list)=='[]' || list.length<50){
						this.finished = true;
					}
					this.loading=false;
					if(this.fromType==1){
						this.shareInfo = data;
					}
					//this.showTotal = data.all_recharge_num>0; 
					this.showTotal = data.show_total; 
					this.fromList = [...this.fromList,...data.list];
					// this.shareInfo=[...this.shareInfo,...list]
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.shareMyData()
			},
		},
	}
</script>
<style>

</style>
<style scoped>
	.nVAOZ5L2ZIX7FtNLSqAD {
	    min-height: .4rem;
	    position: relative;
	    width: 100%
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck {
	    background-color: var(--theme-main-bg-color);
	    border-top: .01rem solid var(--theme-color-line);
	    bottom: 0;
	    font-size: .2rem;
	    line-height: .22rem;
	    padding: .2rem .2rem 0;
	    position: fixed;
	    -webkit-transition: -webkit-transform .3s;
	    transition: -webkit-transform .3s;
	    transition: transform .3s;
	    transition: transform .3s,-webkit-transform .3s;
	    width: 100%
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck.kAaqfTc5guezIg4i8OHU {
	    -webkit-transform: translateY(calc(100% - .3rem));
	    transform: translateY(calc(100% - .3rem))
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 {
	    font-size: .2rem
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col {
	    -ms-flex-align: center;
	    align-items: center;
	    color: var(--theme-text-color-darken);
	    display: -ms-flexbox;
	    display: flex;
	    margin-bottom: .15rem
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col label {
	    color: var(--theme-text-color-lighten);
	    margin-right: .1rem;
	    width: 50%
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 {
	    -ms-flex-align: self-start;
	    -ms-flex-pack: center;
	    align-items: self-start;
	    background-color: var(--theme-main-bg-color);
	    border-radius: .5rem .5rem 0 0;
	    border-top: .02rem solid var(--theme-color-line);
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    color: var(--theme-primary-color);
	    display: -ms-flexbox;
	    display: flex;
	    height: .35rem;
	    justify-content: center;
	    left: 50%;
	    padding: .1rem;
	    position: absolute;
	    top: 0;
	    -webkit-transform: translate(-50%,-98%);
	    transform: translate(-50%,-98%);
	    width: .7rem
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU {
	    font-size: .2rem;
		width: .2rem;
		height: .2rem;
	    -webkit-transition: -webkit-transform .3s;
	    transition: -webkit-transform .3s;
	    transition: transform .3s;
	    transition: transform .3s,-webkit-transform .3s
	}
	
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU.ED6sIMJuJEuPpvzsMw30 {
	    -webkit-transform: rotate(-180deg);
	    transform: rotate(-180deg)
	}
	.ZjZR4FiYzgfJQbE3_Etq {
		padding: .2rem;
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		padding-bottom: .2rem
	}

	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	

	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.vantDataList .van-list>div {
		color: var(--theme-text-color-darken);
		font-size: .2rem !important;
		position: relative
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .s-s svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem;
		margin-left: .1rem;
		color: var(--theme-primary-color);
		position: relative;
	}

	.vantDataList .van-list>div label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem;
	}

	.vantDataList .van-list .van-col {
		margin-bottom: .05rem;
	}

	.dateTime {
		color: var(--theme-text-color-lighten);
		font-size: .16rem;
	}

	.zhMFiMGv4SkVVrKaizPg .dateTime {
		font-size: .2rem !important
	}
</style>
