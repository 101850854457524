<template>
	<div class="C7A8L1rNeyjp6YiJbCkw ">
		<div class="SkyXvrctNhPDnWZtC66x">
			<div class="common-tabs-box1">
			  <ul class="common-tabs-nav P8RuaFULZKF3ME9gfgKl uxYYjiDd7u0QtHMh5yW3" style="width: 1.5rem;">
			      <li class="TAg1HOhNrjpbcFzq75mo active bNrPaNsp97Sa9hmzMKZl subItem JOWqBceNWiyZuqdBAXLg sZKC80YroX_W1LCaI5C_ isVertical event-tab-item tab-1 ">
			        <svg-icon className="imageWrap"  icon-class="icon_dtfl_dz_0--svgSprite:all"  />
			        <div class="vi_TBZvr4AMy0V63Nni7 OYGFKea9dvDSZrAB5rQS text"><span>Slots</span></div>
			      </li>
			    </ul>
			</div>
		</div>
		<div>
			<van-row class="tenConter _border header">
				<van-col class="tr _disps" span="8">
				    Válidos
					<van-popover
					placement="top"
					  v-model="showPopover"
					 theme="dark"
					 style="height: .5rem;display: flex;align-items: center;"
					  trigger="click"
					>
					<van-grid
					    square
					    clickable
						 class="foosuTs"
						 v-html="actions[0].text"
					  >
					  </van-grid>
					<template #reference>
					    <span class="ts">
							  <svg-icon className="imageWrap"  icon-class="comm_icon_wh--svgSprite:all"  />
						</span>
					  </template>
					 <!--  -->
					</van-popover>
					
				</van-col>
				<van-col class="tr _disps" span="8">
					<p style="color: var(--theme-text-color-darken); font-size: 0.24rem;">Desempenho</p>
					<p style="color: var(--theme-text-color-lighten); font-size: 0.24rem;">(Unid: 10,000)</p>
				</van-col>
				<van-col class="tr _disps" span="8">
				    <span class="ant-table-column-title"><span>Comissão por 10,000</span></span>
				</van-col>
			</van-row>
			<div class="tenConter-table-body">
					<van-row  :class="(index%2==0?'tenConter ':'tenConter tc_sae')" v-for="(item,index) in formDelList.list" :key="index">
						<van-col class="tr" span="8">
							<div class="JlCRc6ttKGcNUyDwFygw reward-mixins__levelImage l2ogUtOtFSdH0ER0ochY" style="flex: 0 0 0.7rem; width: 0.7rem; height: 0.7rem; font-size: 0.254545rem;">
								0
							</div>
						</van-col>
						<van-col class="tr" span="8">
							<span>{{item.jiner}}</span>
						</van-col>
						<van-col class="tr" span="8">
							<span class="RL9TJMMzDMOQihFhVnG2">{{item.baifen}}</span>
						</van-col>
				</van-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			name: String,
			item: {
				type: [Array,Object],
				required: [],
			},
			formDelList:{
				type: [Array,Object],
				default:()=>{
					return {
						list:[{
							jiner:0.1,
							baifen:'0.05%',
						},{
							jiner:100,
							baifen:'0.1%',
						},{
							jiner:1000,
							baifen:'0.3%',
						},{
							jiner:5000,
							baifen:'0.5%',
						},{
							jiner:10000,
							baifen:'1%',
						}]
					}
				},
			}
		},
		data() {
			return {
				actions: [{ text: '<div class="ant-popover-inner-content"><p>Condições válidas: O valor da recarga do nível inferior é <em> ≥ 0</em> e a aposta é válida <em> ≥ 0</em></p></div>' }],
				showPopover:false,
			};
		},
		methods: {
			onChange(e) {
				console.log(e)
			}
		}
	};
	
</script>
<style>
	.foosuTs {
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
		padding: .1rem;
		background: var(--theme-main-bg-color);
		    border: thin solid var(--theme-color-line);
		    border-radius: .1rem;
			max-width: 3.6rem;
			    line-height: 1.5;
	}
	.foosuTs.van-hairline--top::after{
		display: none;
	}
	.foosuTs .ant-popover-inner-content{
		 line-height: 1.5;
	}
	.van-popover__content{
		border-radius: .1rem;
	}
	.van-popover--dark .van-popover__arrow{
		background: transparent;
		    border-style: solid;
		    border-width: 4.24264069px;
		    display: block;
		    height: 8.48528137px;
			transform:initial;
		    position: absolute;
		    -webkit-transform: rotate(45deg);
			
		    transform: rotate(45deg);
		    width: 8.48528137px;
			border-bottom-width:initial;
		/* color: var(--theme-main-bg-color); */
		bottom: -4px !important;
		border-style: solid;
		z-index: 99;
		width: 8px;
		border-width: 4px;
		    box-shadow: 3px 3px 7px rgba(0, 0, 0, .07);
		background-color: var(--theme-main-bg-color);
		border-color: var(--theme-color-line) !important;
		border-style: solid;
		border-width: 0 .01rem .01rem 0;
		/* border-color:var(--theme-color-line) !important ; */
	}
</style>
<style scoped>
	.C7A8L1rNeyjp6YiJbCkw  .van-popover__arrow{
	    background-color: var(--theme-main-bg-color);
	    border-color: var(--theme-color-line)!important;
	    border-style: solid;
	    border-width: 0 .01rem .01rem 0
	}
	
	.TAg1HOhNrjpbcFzq75mo{
	  background: var(--theme-primary-color);
	  border: initial;
	    color: var(--theme-primary-font-color);
	  border-radius: .2rem;
	  display: flex;
	  justify-content: center;
	    padding: 0 .1rem;
	    border-radius: .16rem;
	    -ms-flex-direction: column;
	    align-items: center;
	    flex-direction: column;
	    height: 1.05rem;
	}
	
	.TAg1HOhNrjpbcFzq75mo .imageWrap{
	  width: .52rem;
	  height: .82rem;
	}
.SkyXvrctNhPDnWZtC66x {
    height: calc(var(--vh, 1vh)*100 - 2rem)!important;
    margin-right: .2rem;
	padding-right: .3rem;
	box-sizing: border-box;
    max-height: calc(var(--vh, 1vh)*100 - 2rem)!important;
    overflow: scroll
}
.C7A8L1rNeyjp6YiJbCkw {
    background: none;
    -webkit-box-shadow: none;
	display: flex;
	justify-content: space-between;
    box-shadow: none;
    padding: .2rem .2rem 0
}

.header{
	height: .8rem;
	    background: var(--theme-main-bg-color);
		font-size: .24rem;
}
.tenConter .tr._disps{
	display: flex;
	flex-wrap: wrap;
}
.tenConter-table-body .tenConter{
	font-size: .2rem;
	    height: .7rem;
}
.tenConter-table-body .tenConter .JlCRc6ttKGcNUyDwFygw{
	    color: var(--theme-text-color-lighten);
}
.tenConter-table-body .tenConter .RL9TJMMzDMOQihFhVnG2{
	    color: var(--theme-secondary-color-finance) !important;
}
	.JlCRc6ttKGcNUyDwFygw img,.NPzDoGC69c0JdVxoaQso img,.l2ogUtOtFSdH0ER0ochY img {
	    position: absolute
	}
	.JlCRc6ttKGcNUyDwFygw span:before,.NPzDoGC69c0JdVxoaQso span:before,.l2ogUtOtFSdH0ER0ochY span:before {
	    -webkit-background-clip: text;
	    background-clip: text;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f7ea94),color-stop(51%,#e5b952),to(#ce9510));
	    background-image: linear-gradient(180deg,#f7ea94 0,#e5b952 51%,#ce9510);
	    background-size: cover;
	    content: attr(data-text);
	    height: 100%;
	    left: 0;
	    position: absolute;
	    text-shadow: none;
	    top: 0;
	    width: 100%;
	    z-index: 0
	}
	.l2ogUtOtFSdH0ER0ochY {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-position: 50%;
	    background-repeat: no-repeat;
	    background-size: contain;
	    display: flex;
	    justify-content: center;
	    margin: auto;
	    position: relative;
	    text-align: center;
	}
	.JlCRc6ttKGcNUyDwFygw span,.NPzDoGC69c0JdVxoaQso span,.l2ogUtOtFSdH0ER0ochY span {
	    color: transparent;
	    display: inline-block;
	    font-style: normal;
	    font-weight: 700;
	    position: relative;
	    text-shadow: 0 .01rem 0 rgba(0,0,0,.4)
	}
	 ._2lvGimPeAnoEFiUw_fTw {
	    position: relative;
	    width: 3.1rem;
	    margin-top: .1rem;
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner {
	    background-color: var(--theme-text-color-placeholder);
	    border-radius: .2rem
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner .ant-progress-bg {
	    background-color: var(--theme-vip-progress-bg-color);
	    border-radius: .2rem !important;
	    height: .2rem !important;
	}
	._2lvGimPeAnoEFiUw_fTw>span {
	    -webkit-background-clip: text!important;
	    color: #fff;
	    font-size: .16rem;
	    left: 50%;
	    overflow: hidden;
	    position: absolute;
	    text-align: center;
	    text-overflow: ellipsis;
	    top: 60%;
	    transform: translate(-50%,-58%);
	    white-space: nowrap;
	    width: 94%
		}

</style>