<template>
	<div class=" ant-select-enabled"  @click="$emit('_click')">
		<div class="TGxvcgbTuwrxQwhlOYhR" >
			<img src="./../../assets/icons/images/icon_normal_pix.png" alt="."
				class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" basescale="0.8"
				style="width: 0.48rem; height: 0.48rem;">
				<span style="    color: var(--theme-text-color-darken);">PIX</span>
		</div>
		<div class="tight" >
			
			<span>{{ $t('withdraw._Adicionar') }}</span>
			<svg-icon class="tight-svg"
				icon-class="comm_icon_fh--svgSprite:all" />
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				isShow:false
			}
		}
	}
</script>

<style scoped>
	.ant-select-enabled {
		background-color: var(--theme-main-bg-color);
		border-radius: .14rem;
		width: 100%;
		box-sizing: border-box;
		border: 1px solid var(--theme-color-line);
		font-size: .22rem;
		height: .7rem;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: space-between;
		line-height: .7rem;
		text-indent: .05rem;
		padding-left: .2rem;
		padding-right: .2rem;
		border: solid .02rem;
		border-color: var(--theme-color-line);
	}
	.ant-select-enabled:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important;
		outline: 0
	}
	.tight{
		display: flex;
		align-items: center;
	}
	.tight span{
		    color: var(--theme-ant-primary-color-0);
	}
	.tight-svg{
		    color: var(--theme-text-color-lighten);
			    transform: rotate(180deg);
				margin-left: .15rem;
				width: .26rem;
				height: .26rem;
	}
	.TGxvcgbTuwrxQwhlOYhR {
		display: flex;
		align-items: center;
	}

	.TGxvcgbTuwrxQwhlOYhR>span:first-of-type {
		margin-left: .2rem
	}

	.TGxvcgbTuwrxQwhlOYhR span {
		font-size: .22rem;
		color: var(--theme-text-color-lighten);
	}

	.ant-select-enabled {
		border-color: var(--theme-ant-primary-color-13);
		outline: 0
	}

	.ant-select-arrow-icon {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: inherit;
		color: rgba(0, 0, 0, .25);
		display: inline-block;
		font-size: 12px;
		font-style: normal;
		line-height: 0;
		line-height: 1;
		margin-top: -6px;
		position: absolute;
		right: 11px;
		text-align: center;
		text-transform: none;
		top: 50%;
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		vertical-align: -.125em
	}


	.ant-select-arrow-icon.anticon {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
	}
	.ant-select-dropdown {
	    background-color: var(--theme-main-bg-color);
	    border: .01rem solid var(--theme-color-line) !important;
	    border-radius: .1rem;
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
	    box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
	}
	.ant-select-dropdown .cl {
		color: var(--theme-primary-color);
	}
	.ant-select-dropdown-menu{
		    max-height: 5.1rem;
		    padding: .14rem 0;
			    overflow: auto !important;
	}
	.ant-select-dropdown .ant-select-dropdown-menu-item {
	    font-size: .24rem;
	    line-height: .8rem;
	    min-height: .8rem;
	    padding: 0 .2rem;
	}
</style>

	

