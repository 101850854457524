<template>
	<div class="promote">
		<van-nav-bar title="Pesquisar" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="WQRH7kQFHu0625bXlsSq">
			<div class="_PUxRK2xxVs7RWk3be3YQ">
				<searchFor placeholder="Pesquisar"  :vModel="formName" @blur="searchFuConter"  @search="searchFuConter"  @keydown="handleKeydown" className="search-bac-s"></searchFor>
			</div>
			<div class=""  ref="myElement"  v-if="searchList.length">
				<div class="BbCWc5HBE29rTXaWvmW9 _PUxRK2xxVs7RWk3be3YQ">
					<div class="l">
						<svg-icon icon-class="comm_icon_clock--svgSprite:all" />
						<span>Histórico</span>
					</div>
					<div class="r" @click="deleteSearch('delete')">
						<svg-icon icon-class="comm_icon_del--svgSprite:all" />
						<span>Deletar tudo</span>
					</div>
				</div>
				<div class="KPde_9JUTJjmM6WyyeRA" >
					<ul>
						<li v-for="(item,index) in searchList" :key="index">
							<span @click="searchGame(item)">{{item}}</span>
							<div @click="deleteSearch(index)">
								<svg-icon  className="imageWrap" icon-class="comm_icon_x--svgSprite:all" />
							</div>
						</li>
					</ul>
				</div>
				
			</div>

		</div>
		<div class="tab-search">
			<!-- <div v-if="$store.state.sLoading" style="width: 100%;height: calc(100vh - 3.7rem);display: flex;justify-content: center;align-items: center;">
				<sLoading/>
			</div> -->
			<van-tabs v-model="tabActive" sticky
				offset-top=".9rem"
				animated
				:lineWidth="(activeLength*10+'px')"
				line-height='0.02rem'
				@change="funTabConte"
				background="transparent" 
				title-active-color="var(--theme-primary-color)"
				title-inactive-color="var(--theme-text-color-darken)"
				color="var(--theme-primary-color)"
				>
				<van-tab v-for="(item,index) in tabList" :key="index" :name="index">
					<div slot="title" class="h5MidMenuTab" :name="index">
						<div class="N4LsWhJBhqqyTkv6V0NE search-active-name">
							<img class="imageWrap" :src="ICON_search[index]" alt="">
							{{item.name}}
						</div>
					</div>
					<div class="search-gameItemBox" :style="{
						overflow:'scroll',
						height:`calc(100vh - ${searchListHeigh+2.6}rem )`
					}">
					<!-- <div class="gameItemBox search-gameItemBox" :style="{
						overflow:'scroll',
						height:`calc(100vh - ${searchListHeigh+?'4.8rem':'2.7rem'} )`
					}"> -->
					<div v-if="!itemListConter.count" style="height: 100%;width: 100%;;display: flex;justify-content: center;align-items: center;">
								  <van-empty
									  image-size="2.1rem"
									  style="text-align: center;"
									  class="custom-image"
									  :image="img_none_sj"
									  description="Nenhum jogo disponível"
								  />
					</div>
					 <component :itemListConter="itemListConter" :is="item.content"></component>
					  <!-- <gameItem  v-for="(item,index) in itemListConter.list" :item="item" :key="index"></gameItem> -->
					</div> 
				</van-tab>
			</van-tabs>
		</div>
		
	</div>
</template>

<script>
	import searchFor from "@/components/searchFor/searchFor.vue";
	import {ICON_search} from "@/assets/public/icon_nav.js"
	import gameItem from "./item.vue"
	
	import {searchGame,getGameList} from "@/apis/modules/subgame.js"
	// ICON_search
	export default {
		components: {
			searchFor,
			gameItem
		},
		data() {
			return {
				tabActive:1,
				activeLength:'9',
				_isvanLoading:false,
				ICON_search:ICON_search,
				tabList:[
					{
						name:'Pesquisar',
						category:"search",
						content:gameItem,
						//popular/recent/favorite，
						// search/slot/piscator/demo
					},
					{
						name:'Popular',
						category:'popular',
						content:gameItem,
					},
					{
						name:'Recente',
						category:'recent',
						content:gameItem,
						
					},
					{
						name:'Favoritos',
						category:"favorite",
						content:gameItem,
					},
					
				],
				itemListConter:{
					
				},
				searchList:[],
				searchListHeigh:'',
				formName:'',
				refValue:'',
				formTYpe:'popular'
				// Pesquisar
			}
		},
		created() {
			this.getGameList();
		},
		mounted(){
			let searchList=window.localStorage.getItem('searchList');
			this.searchList=searchList?searchList.split(','):[];
			this.$nextTick(() => {
			  let refValue = this.$refs.myElement;
			  this.refValue=refValue;
			  this.getHeight();
			});
		},
		methods: {
			getGameList(el){
				// category:this.formTYpe,
				// size:9999
				getGameList({
					platform:this.formTYpe,
					category:'search'
				}).then(({data})=>{
					if(data.list){
						this.itemListConter=data;
					}else{
						this.itemListConter={};
					}
					// this.$store.commit('sLoading',false)
				})
			},
			deleteSearch(index){
				if(index=='delete'){
					this.searchList=[];
				}
				let repeatData =this.searchList
				this.searchList=repeatData.filter((item,_index)=>{
					return _index!=index
				})
				window.localStorage.setItem('searchList',this.searchList);
				setTimeout(()=>{
					this.getHeight();
				},150)
			},
			getHeight(){
				const myElement = this.refValue;
			  // 获取元素高度
				if(myElement){
					 const height = myElement.offsetHeight;
						this.searchListHeigh= height/50;
				}
			},
			searchFuConter(name){
				if(typeof name=="string"){
					this.formName=name;
				}else{
					this.formName=name.target.value;
				}
				if(!this.formName){
					return false;
				}
				this.itemListConter={};
				let repeatData =this.searchList
				repeatData.unshift(this.formName);
				let map = new Map();
				repeatData.forEach(item => {
				  map.set(item, item);
				});
				this.searchList=[...map.values()];
				window.localStorage.setItem('searchList',this.searchList);
				this.formTYpe='search'
				this.tabActive=0;
				this.searchGame()
			},
			searchGame(name){
				
				if(typeof name === "string"){
					this.formName=name;
					this.tabActive=0;
					this.formTYpe='search'
				}else if(name){
					let repeatData =this.searchList
					repeatData.unshift(this.formName);
					let map = new Map();
					repeatData.forEach(item => {
					  map.set(item, item);
					});
					this.searchList=[...map.values()];
					window.localStorage.setItem('searchList',this.searchList);
					this.formTYpe='search'
					this.tabActive=0;
				}
				this.getHeight();
				this.$store.commit('sLoading',true)
				searchGame({
					category:this.formTYpe,
					name:this.formName,
					size:9999
				}).then(({code,mas,data})=>{
					if(data.list){
						this.itemListConter=data;
					}else{
						this.itemListConter={};
					}
					this.$store.commit('sLoading',false)
				})
			},
			funTabConte(e,index){
				this.formTYpe=this.tabList[e].category
				this.itemListConter={};
				this.formName='';
				if(e){
					this.getGameList();
				}
			},
			handleKeydown(e){
				this.formName=e.target.value
				if (event.key === 'Enter') {
					if(!e.target.value){
						return false;
					}
					this.tabActive=0;
					this.searchGame();
					let repeatData =this.searchList
					repeatData.unshift(this.formName);
					let map = new Map();
					repeatData.forEach(item => {
					  map.set(item, item);
					});
					this.searchList=[...map.values()];
					window.localStorage.setItem('searchList',this.searchList)
					this.formTYpe='search';
					setTimeout(()=>{
						this.getHeight();
					},150)
				}
				
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>
<style>
	.search-bac-s .ant-input {
		background-color: transparent !important;
	}
	.search-gameItemBox .game_img{
		    margin: var(--cardColSpacing) var(--cardColSpacing) 0.2rem var(--cardColSpacing) !important;
	}
</style>
<style scoped>
	.WQRH7kQFHu0625bXlsSq {
		padding:.2rem .2rem 0 .2rem;
	}

	.BbCWc5HBE29rTXaWvmW9 {
		padding-top: .2rem;
		font-size: .22rem;
		justify-content: space-between;
	}

	.BbCWc5HBE29rTXaWvmW9 .l {
		color: var(--theme-text-color-lighten);
	}

	.BbCWc5HBE29rTXaWvmW9 .r {
		/* color: var(--theme-text-color-lighten); */
		color: #ea4d3d;
	}

	.BbCWc5HBE29rTXaWvmW9 svg {
		width: .24rem;
		height: .24rem;
		margin-right: .1rem;
	}
	.search-active-name{
		display: flex;
		font-size: .24rem;
		font-weight: 400;
		align-items: center;
	}
	.search-active-name img{
		width: .38rem;
		height: .38rem;
		margin-right: .1rem;
	}
	.KPde_9JUTJjmM6WyyeRA {
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-top: .2rem;
		max-height: 1.5rem;
	}
	.KPde_9JUTJjmM6WyyeRA ul{
		display: flex;
		-ms-flex-wrap: wrap;
		padding-top: .2rem;
		flex-wrap: wrap;
		max-height: 1.3rem;
		overflow: hidden;
	}
	.search-gameItemBox{
		padding-top: .2rem;
	}
	
	.KPde_9JUTJjmM6WyyeRA li {
		-ms-flex-align: center;
		align-items: center;
		background: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .2rem;
		color: var(--theme-text-color-lighten);
		cursor: pointer;
		display: -ms-flexbox;
		display: flex;
		height: .4rem;
		margin-bottom: .2rem;
		margin-right: .25rem;
		padding: 0 .15rem;
		position: relative;
		font-size: .22rem;
		border-radius: .25rem;
		height: .5rem;
		margin-right: .3rem;
		padding: 0 .18rem;
	}
	.KPde_9JUTJjmM6WyyeRA li span{
		display: inline-block;
		font-size: .18rem;
		max-width: 2rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.KPde_9JUTJjmM6WyyeRA li svg {
		position: absolute;
		width: .12rem;
		height: .12rem;
		font-size: .12rem;
		color: #fff;
		right: -.1rem;
		background: var(--theme-text-color-placeholder);
		border-radius: 50%;
		top: -.1rem;
		border: .07rem solid var(--theme-text-color-placeholder);
		/* width: .26rem; */
	}
	.tab-search{
		padding: 0 .2rem;
	}
</style>
