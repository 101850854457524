<template>
	 <div style="display: flex;flex-wrap: wrap;width:100%">
		 <gameItem  v-for="(item,index) in itemListConter.list" :item="{...item,type:'game'}"  :key="index"></gameItem>
		 
	 </div>
</template>

<script>
	import gameItem from "@/components/gameItem/index.vue"
	export default{
		components: {
			gameItem
		},
		props:{
			itemListConter:{
				type:[Array,Object],
				required: {},
			}
		}
	}
</script>

<style>
</style>