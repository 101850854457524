<template>
	<div class="ContaBox">
		<div style="display: flex;justify-content: space-between;width: 100%;overflow: auto;">
			<!-- sss -->
			<div class="my-scrollbar" style="margin-bottom: .2rem;overflow-x: scroll;overflow-y: hidden;">
				<!-- <ant-select :value="timValue" :options="optionTim" @change="_onChangeTime">
				</ant-select> -->
				<elSelectItem :options="optionTim" @change="_onChangeTime" :value="timValue"></elSelectItem>
				<!-- <ant-select style="margin-left: .2rem;" :value="game_typeValue" :options="game_type"
					@change="_onChangeGame_type">
				</ant-select> -->
				<elSelectItem style="margin-left: .2rem;" :options="game_type" @change="_onChangeGame_type" :value="game_typeValue"></elSelectItem>
				<!-- <ant-select style="margin-left: .2rem;" value="1" :options='[{text:"Todas as Plataformas",value:"1"}]'
					>
				</ant-select> -->
				<elSelectItem style="margin-left: .2rem;"  value="1" :options='[{text:"Todas as Plataformas",value:"1"}]'></elSelectItem>
			</div>
			<div class="Ez1c7PkAs5rYSCRy9eeB"  @click="arrTime==''?arrTime='ant':arrTime=='ant'?arrTime='lant':arrTime=''">
				<div class="BR5wLnCNvHZmz12VrQIK">
					<p>Apostas/</p>
					<p>Valor/</p>
					<p>Ganhos</p>
				</div>
				<span class="KbIipJmp5a6OfjK58SlT">
					<span 
					 :class="{
						upSxJysb76gS73lkVnUa:arrTime=='ant',
					 }"
					 class="E9MTgrF_INGoXMxpvQiy "></span>
					<span :class="{
						_2I4rmrxpydl05x4IMZP:arrTime=='lant',
					 }" class="gBHP20wzU6jCiRFMIdCN "></span>
				</span>
			</div>
		</div>
		
		<div class="TBtGFT5INIot5sEUIm8w vantDataList ">
			<van-list v-if="shareInfo.list && shareInfo.list.length" v-model="loading" @load="onLoad"  :finished="finished"
				>
				<div class="yirWkOwey8HB15QLXqmz" v-for="(item,index) in shareInfo.list"
					:key="index" :title="item">
					<div class="E8vjOrxznOAKdRhXJRMX">
						<p><span>{{item.game}}</span></p>
						<p><span>{{item.date || item.create_time}}</span></p>
					</div>
					<div class="E8vjOrxznOAKdRhXJRMX hm5kVDuxEiHavCx1SBCQ">
						<p><span><span class="UXsnlJzbtvsfRVKRTLXu">{{item.count}}</span>/<span class="UXsnlJzbtvsfRVKRTLXu">R$
									{{item.bet}}</span>/<span  
									 
									 :class="{
										 fABmUWsKxI0hI9mbzoYD:item.gain>0,
										 YgwzG0T3H3PBaUc3A3_s:item.gain<0
									 }"
									 class="YgwzG0T3H3PBaUc3A3_s">{{item.gain<0?'-':'+'}}R${{item.gain}}</span></span></p>
					</div>
				</div>
				<!--  -->

				<!--  -->
			</van-list>
			<van-empty image-size="2.1rem" v-if="!shareInfo.list || !shareInfo.list.length" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
				<div class="waxH0FBvm5SKSHEIdmzj wg-fixed-no-desktop" style="z-index: 9999999999;">
					<div class="pBg296B2aLWiyWbbg5An">
						
						<span class="RjCX53sI34q23D2TwPWl">
							<span>{{ $t("report._Valorcumulativodaaposta") }}</span>
							<span class="VsQlwP6H52Vyv4bEeq1q HGHvRuWIBhpGiBhkEAHu">{{shareInfo.all_count}}</span>
						</span>
						<span class="RjCX53sI34q23D2TwPWl">
							<span>{{ $t("report._TotaldeApostasVálidas") }}</span>
							<span class="kfrOXvCeC3B_UykAtxE_ HGHvRuWIBhpGiBhkEAHu">R$ {{shareInfo.all_bet}}</span>
						</span>
						<span class="RjCX53sI34q23D2TwPWl">
							<span>{{ $t("report._TotaldeV") }}</span>
							<span class="cVihnbOVCk0AV6cIk86g">R${{shareInfo.all_gain}}</span>
						</span>
					</div>
				</div>
		
		</div>
		

	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	import elSelectItem from "./el-select-item.vue";
	import {
		ContaType,
		tim,
		timValue,
		ContaTypeValue,
		game_type,
		game_typeValue
	} from "@/utils/tim.js"
	// allData
	import {
		getBetReport
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor,
			elSelectItem
		},

		data() {
			return {
				list: [],
				isTanzhans: false,
				loading: false,
				finished: false,
				shareInfo: {},
				arrTime:'',
				shareTime: 'Ontem',
				ContaType: ContaType,
				timValue: timValue,
				optionTim: tim,
				game_type: game_type,
				game_typeValue: game_typeValue,
				ContaTypeValue: ContaTypeValue,
				active: 0,
				fromType:1,
				formData: {
					time: '',
				}
			}
		},
		mounted() {
			this.optionTim=tim.filter(item=>{
				return item.is!==false
			})
			this.getBetReport();
			
		},
		methods: {
			_onChangeTime(e) {
				this.formData.time = e[0];
				this.timValue=e[0]
				this.shareInfo={};
				this.getBetReport();
			},
			_onChangeGame_type(e) {
				this.formData.type = e[0];
				this.game_typeValue=e[0]
				this.shareInfo={};
				this.getBetReport();
			},
			_search(e) {
				this.formData.search_id = e;
				this.getBetReport();
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				this.fromType=this.fromType+1;
				this.getBetReport()
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				// setTimeout(() => {
				// 	for (let i = 0; i < 10; i++) {
				// 		this.list.push(this.list.length + 1);
				// 	}

				// 	// 加载状态结束
				// 	this.loading = false;

				// 	// 数据全部加载完成
				// 	if (this.list.length >= 1000) {
				// 		this.finished = true;
				// 	}
				// }, 1000);
			},
			getBetReport() {
				// vuex_token_head
				getBetReport({
					page:this.fromType,
					pid: this.$store.state.vuex_token_head.user_id,
					...this.formData
				}).then(({
					data
				}) => {
					if(JSON.stringify(data.list)=='[]' || data.list.length<50){
						this.finished = true;
					}
					if(data.list && this.shareInfo.list){
						data.list = [...this.shareInfo.list,...data.list]
					}
					this.loading = false;
					if(this.fromType==1){
						this.shareInfo = data;
					}else{
						this.shareInfo.list = [...this.shareInfo.list,...data.list];
					}
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.shareMyData()
			},
		},
	}
</script>
<style>

</style>
<style scoped>
	.Ez1c7PkAs5rYSCRy9eeB {
	    -ms-flex-align: center;
	    align-items: center;
	    color: var(--theme-text-color-lighten);
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .2rem;
	    height: .5rem;
	    padding-left: .1rem;
	    padding-right: .1rem
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .BR5wLnCNvHZmz12VrQIK {
	    font-size: .14rem;
	    left: 0;
	    position: relative;
	    text-align: right;
	    -webkit-transform: scale(.9);
	    transform: scale(.9);
	    width: 1.2rem
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .BR5wLnCNvHZmz12VrQIK p {
	    word-wrap: break-word
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .WBSP9JquSYDBIjU4WSN9 {
	    width: 1.6rem
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .KbIipJmp5a6OfjK58SlT {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    justify-content: center;
	    margin-left: .15rem
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .KbIipJmp5a6OfjK58SlT .gBHP20wzU6jCiRFMIdCN {
	    border: .07rem solid transparent;
	    border-top: .09rem solid var(--theme-text-color-lighten);
	    height: 0;
	    width: 0
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .KbIipJmp5a6OfjK58SlT ._2I4rmrxpydl05x4IMZP {
	    border-top: .09rem solid var(--theme-primary-color)
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .KbIipJmp5a6OfjK58SlT .E9MTgrF_INGoXMxpvQiy {
	    border: .07rem solid transparent;
	    border-bottom: .09rem solid var(--theme-text-color-lighten);
	    height: 0;
	    margin-bottom: .03rem;
	    width: 0
	}
	
	.Ez1c7PkAs5rYSCRy9eeB .KbIipJmp5a6OfjK58SlT .upSxJysb76gS73lkVnUa {
	    border-bottom: .09rem solid var(--theme-primary-color)
	}
	.wg-fixed-no-desktop{
		left:initial;
	}
	.my-scrollbar {
		display: flex;
		padding-left: .2rem;
		overflow: auto;
	}
	
	.yirWkOwey8HB15QLXqmz {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .2rem;
	    justify-content: space-between;
	    padding: .2rem!important
	}
	.ContaBox {
		padding-top: .2rem;
	}

	.nVAOZ5L2ZIX7FtNLSqAD {
		min-height: .4rem;
		position: relative;
		width: 100%
	}
	.Bb2eRv4VS7avN4IlErAm>span {
	    display: inline-block;
	    vertical-align: middle;
	    white-space: nowrap
	}
	
	.Bb2eRv4VS7avN4IlErAm>span:first-child {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.H9OepNjwbd602I30Pp_s {
	    -ms-flex-pack: justify;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    font-size: .2rem;
	    justify-content: space-between;
	    padding: .2rem
	}
		.E8vjOrxznOAKdRhXJRMX {
		    color: var(--theme-text-color-lighten)
		}
		.UXsnlJzbtvsfRVKRTLXu {
		    color: var(--theme-text-color)
		}

	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
	    padding: .18rem .1rem!important
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG {
	    margin-right: .1rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 2.4rem
	}
	.copyIcon svg{
		    color: var(--theme-primary-color);
			width: .22rem;
			height: .22rem;
	}
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG:not(:lang(zh_CN)) {
	    width: 2.5rem
	}
	.YgwzG0T3H3PBaUc3A3_s {
	    color: var(--theme-secondary-color-success)
	}
	.fABmUWsKxI0hI9mbzoYD{
		    color: var(--theme-secondary-color-error);
	}

	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7 {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: 1;
	    flex: 1
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span {
	    width: 50%
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:first-child:not(:lang(zh_CN)) {
	    display: inherit;
	    width: 2.2rem
	}
	.pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}
	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    margin: 0;
	    width: 50%
	}
	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl>span {
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    display: -webkit-box;
	    margin: 0;
	    overflow: hidden;
	    padding-right: .05rem;
	    text-overflow: ellipsis;
	    vertical-align: middle;
	    width: 50%;
	    word-break: break-all
	}
	.pBg296B2aLWiyWbbg5An {
	    -ms-flex-line-pack: space-evenly;
	    align-content: space-evenly;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
		    color: var(--theme-text-color);
	    font-size: .22rem;
		font-weight: 200;
	    margin-bottom: 0;
	    min-height: 1rem;
	    padding: .05rem .2rem .1rem;
	    width: 100%
	}
	 .RjCX53sI34q23D2TwPWl>span:last-child {
	    padding-left: .2rem;
	    padding-right: 0
	}
	.pBg296B2aLWiyWbbg5An .HGHvRuWIBhpGiBhkEAHu {
	    color: var(--theme-text-color-darken);
	}
	
	.pBg296B2aLWiyWbbg5An .HNKlmlfGsE25ksqykrVs {
	    color: var(--theme-secondary-color-error);
	}
	.pBg296B2aLWiyWbbg5An .VsQlwP6H52Vyv4bEeq1q {
	    color: var(--theme-secondary-color-success);
	}
	
	.pBg296B2aLWiyWbbg5An .kfrOXvCeC3B_UykAtxE_ {
	    color: var(--theme-secondary-color-error);
	}
	
	.pBg296B2aLWiyWbbg5An .cVihnbOVCk0AV6cIk86g {
	    color: var(--theme-secondary-color-finance);
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:nth-child(2):not(:lang(zh_CN)) {
	    -ms-flex-pack: end;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: flex-end;
	    margin-left: .1rem;
	    white-space: nowrap;
	    width: 1.9rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa {
	    -ms-flex-align: center;
	    word-wrap: break-word;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	    margin-top: .12rem;
	    word-break: keep-all
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd {
	    margin-right: .1rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 2.4rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd:not(:lang(zh_CN)) {
	    width: 2.5rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN {
	    -ms-flex-pack: justify;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: 1;
	    flex: 1;
	    justify-content: space-between
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_ {
	    max-width: 3.8rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_:not(:lang(zh_CN)) {
	    max-width: 3.86rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN .zrPmGto4tAdnAA_BFB54 {
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .22rem
	}
		
	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
	    padding: .18rem .1rem!important
	}
		
		
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck {
		background-color: var(--theme-main-bg-color);
		border-top: .01rem solid var(--theme-color-line);
		bottom: 0;
		font-size: .2rem;
		line-height: .22rem;
		padding: .2rem .2rem 0;
		position: fixed;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
		width: 100%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck.kAaqfTc5guezIg4i8OHU {
		-webkit-transform: translateY(calc(100% - .3rem));
		transform: translateY(calc(100% - .3rem))
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 {
		font-size: .2rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .15rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem;
		width: 50%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 {
		-ms-flex-align: self-start;
		-ms-flex-pack: center;
		align-items: self-start;
		background-color: var(--theme-main-bg-color);
		border-radius: .5rem .5rem 0 0;
		border-top: .02rem solid var(--theme-color-line);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		height: .35rem;
		justify-content: center;
		left: 50%;
		padding: .1rem;
		position: absolute;
		top: 0;
		-webkit-transform: translate(-50%, -98%);
		transform: translate(-50%, -98%);
		width: .7rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU {
		font-size: .2rem;
		width: .2rem;
		height: .2rem;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU.ED6sIMJuJEuPpvzsMw30 {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg)
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		padding: .2rem;
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		padding-bottom: .2rem
	}

	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.vantDataList .van-list>div .isGreen {
		color: var(--theme-secondary-color-success)
	}

	.vantDataList .van-list>div .isRed {
		color: var(--theme-secondary-color-error)
	}



	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.vantDataList .van-list>div {
		color: var(--theme-text-color-darken);
		font-size: .2rem !important;
		position: relative
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .s-s svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem;
		margin-left: .1rem;
		color: var(--theme-primary-color);
		position: relative;
	}

	.vantDataList .van-list>div label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem;
	}

	.vantDataList .van-list .van-col {
		margin-bottom: .05rem;
	}

	.dateTime {
		color: var(--theme-text-color-lighten);
		font-size: .16rem;
	}

	.greenColor {
		color: var(--theme-secondary-color-error) !important;
	}

	.redColor {
		color: var(--theme-secondary-color-success) !important;
	}

	.zhMFiMGv4SkVVrKaizPg .dateTime {
		font-size: .2rem !important
	}
</style>
