<template>
	<div class="SeAbj9DqXIhIurVLjTOf">
		<div class="RU9ifcibtBDTqaoRHkOJ timeSelectRoot">
			<div class="jcudJ3DGG2ThEXZWXkIT">
				<!-- '_Ontem:"Ontem",_Hoje:"Hoje",_EstáSemana:"Está Semana",_ÚltimaSemana:"Última Semana",_EsteMês:"Este Mês",_MêsPassado:"Mês Passado",' -->
				<!-- JzUfpgiRzooKmArhWe8e -->
				<!-- DJ_mSvGb16Vm2TCY_nub -->
				<!-- DJ_mSvGb16Vm2TCY_nub -->
				
				<div class="DJ_mSvGb16Vm2TCY_nub" :class="{
					JzUfpgiRzooKmArhWe8e:!active,
				}" 
				@click="tabIndexTime(0,'yesterday')"
				>{{ $t('promote._Ontem')}}</div>
				<div class="DJ_mSvGb16Vm2TCY_nub"
				 :class="{
				 	JzUfpgiRzooKmArhWe8e:active==1,
				 }"
				 @click="tabIndexTime(1,'today')"
				 >{{ $t('promote._Hoje')}}</div>
				<div class="DJ_mSvGb16Vm2TCY_nub" 
				:class="{
					JzUfpgiRzooKmArhWe8e:active==2,
				}"
				@click="tabIndexTime(2,'week')"
				>{{ $t('promote._EstáSemana')}}</div>
				<div class="DJ_mSvGb16Vm2TCY_nub" 
				 :class="{
				 	JzUfpgiRzooKmArhWe8e:active==3,
				 }"
				 @click="tabIndexTime(3,'last_week')"
				 >{{ $t('promote._ÚltimaSemana')}}</div>
				<div class="DJ_mSvGb16Vm2TCY_nub"
				 :class="{
				 	JzUfpgiRzooKmArhWe8e:active==4,
				 }"
				 @click="tabIndexTime(4,'month')"
				 >{{ $t('promote._EsteMês')}}</div>
				<div class="DJ_mSvGb16Vm2TCY_nub"
				 :class="{
				 	JzUfpgiRzooKmArhWe8e:active==5,
				 }"
				 @click="tabIndexTime(5,'last_month')"
				 >{{ $t('promote._MêsPassado')}}</div>
			</div>
		</div>
		<!-- 
		 _Adicionarmembrosdiretos:"Adicionar membros diretos",
		 _PrimeirosDepósitos:"Primeiros Depósitos",
		 _Depósitos:"Depósitos",_Depósito:"Depósito",
		 _Desempenho:"Desempenho",_Comissão:"Comissão",
		 _VisãoGeraldosDados:"Visão Geral dos Dados",
		 _MeuTime:"Meu Time",_Desempenho:"Desempenho",_Comissão:"Comissão",
		 -->
		<section class="FNuttToTMkScYCWLSpAX">
			<div class="EB65ojGhkVbrhClXwc3T">
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._Adicionarmembrosdiretos')}}</span><span
						class="vYuETMZ5uBHm1wD46BdH"><span>{{shareInfo.invite}}</span></span></div>
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._PrimeirosDepósitos')}}</span><span
						class="vYuETMZ5uBHm1wD46BdH"><span>{{shareInfo.first_recharge}}</span></span></div>
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._Depósitos')}}</span><span
						class="vYuETMZ5uBHm1wD46BdH"><span>{{shareInfo.recharge_num}}</span></span></div>
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._Depósito')}}</span><span class="vYuETMZ5uBHm1wD46BdH"><span
							class="currencyAmount">{{shareInfo.recharge_total}}</span></span></div>
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._Desempenho')}}</span><span class="vYuETMZ5uBHm1wD46BdH"><span
							class="currencyAmount">{{shareInfo.performance}}</span></span></div>
				<div class="jYmlq4MNh5J8KO375eTB boxShadow theme-box-shadow-h5"><span
						class="Oxprin8vMs6tWsDhs0RF">{{ $t('promote._Comissão')}}</span><span class="vYuETMZ5uBHm1wD46BdH"><span
							class="currencyAmount yellowColor">{{shareInfo.commission}}</span></span></div>
			</div>
		</section>
		<section class="FNuttToTMkScYCWLSpAX">
			<div class="DpnAIZWql0sJQ_AyNOE2 boxShadow theme-box-shadow-h5">
				<div class="avMNK8Qg8S6ELvNBn4SS"><span>{{ $t('promote._VisãoGeraldosDados')}}</span><span></span></div>
				<div class="aRAlpf3Aum4EXH0wnIwd">
					<div class="vzG9Liccdun0wlTHfCOB" >
						<div @click="$emit('tabModel',5)">
							<div class="N7fdZkhB2FrpFTuNa4Vf"><span> {{ $t('promote._MeuTime')}}</span></div>
						</div>
						<!-- 
						
						-->
						<div class="zRdyabQdPLLOQmV6vfWA">
							<span>{{ $t('promote._Membros')}}</span><span><span v-if="shareInfo.my_team" class="currencyAmount">
							{{shareInfo.my_team.total_invite}}</span></span>
						</div>
						<div class="zRdyabQdPLLOQmV6vfWA">
							<span>{{ $t('promote._MembrosDiretos')}}</span>
							<span>
							<span
									class="currencyAmount" v-if="shareInfo.my_team"> {{shareInfo.my_team.n1_invite}}</span>
									</span>
						</div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span>{{ $t('promote._OutrosMembros')}} </span><span><span v-if="shareInfo.my_team" class="currencyAmount">
									{{shareInfo.my_team.n2_invite}}</span></span> 
						</div>
					</div>
					<div class="vzG9Liccdun0wlTHfCOB"  >
						<div @click="$emit('tabModel',3)">
							<div class="N7fdZkhB2FrpFTuNa4Vf"><span>{{ $t('promote._Desempenho')}}</span></div>
						</div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span>{{ $t('promote._RendimentoTotal')}} </span><span><span
									class="currencyAmount" v-if="shareInfo.my_performance">{{shareInfo.my_performance.total_performance}}</span></span></div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span> {{ $t('promote._DDireto')}} </span><span><span  v-if="shareInfo.my_commission" class="currencyAmount">
									{{shareInfo.my_performance.n1_performance}}</span></span></div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span >{{ $t('promote._DdosOutros')}}</span><span><span  v-if="shareInfo.my_commission" class="currencyAmount">
									{{shareInfo.my_performance.n2_performance}}</span></span></div>
					</div>
					<div class="vzG9Liccdun0wlTHfCOB"  >
						<div @click="$emit('tabModel',4)">
							<div class="N7fdZkhB2FrpFTuNa4Vf"><span>{{ $t('promote._Comissão')}}</span></div>
						</div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span>{{ $t('promote._ComissõesTotais')}}</span><span><span
									class="currencyAmount yellowColor" v-if="shareInfo.my_performance">{{shareInfo.my_commission.total_commission}}</span></span></div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span>{{ $t('promote._ComissãoDireta')}}</span><span><span
									class="currencyAmount yellowColor" v-if="shareInfo.my_performance">{{shareInfo.my_commission.invite_commission}}</span></span></div>
						<div class="zRdyabQdPLLOQmV6vfWA"><span>{{ $t('promote._OutraComissão')}}</span><span><span
									class="currencyAmount yellowColor" v-if="shareInfo.my_performance">{{shareInfo.my_commission.other_commission}}</span></span></div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import {
		shareMyData
	} from "@/apis/modules/share.js";
	import QRCode from 'qrcode';
	export default {
		data() {
			return {
				shareInfo: {},
				shareTime:'yesterday',
				active:0,
			}
		},
		mounted() {
			this.shareMyData(true);
		},
		methods: {
			shareMyData(isTrue) {
				shareMyData({
					time:isTrue ? '':this.shareTime,
				}).then(({
					data
				}) => {
					this.shareInfo ={
						...this.shareInfo,
						...data,
					} ;
				})
			},
			tabIndexTime(index,time){
				this.shareTime=time;
				this.active=index;
				this.shareMyData()
			},
		},
		}
</script>

<style scoped>
	
	.RU9ifcibtBDTqaoRHkOJ {
	    overflow-x: scroll;
	    width: 100%
	}
	.RU9ifcibtBDTqaoRHkOJ::-webkit-scrollbar {
	  display: none; /* 针对Webkit浏览器，如Chrome、Safari */
	}
	.RU9ifcibtBDTqaoRHkOJ {
	  -ms-overflow-style: none; /* 针对IE、Edge浏览器 */
	  scrollbar-width: none; /* 针对Firefox浏览器 */
	}
	.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT {
	    -ms-flex-pack: start;
	    border-bottom: .01rem solid var(--theme-color-line);
	    justify-content: flex-start;
	    min-width: 100%;
	    padding: .2rem;
	    width: -webkit-max-content;
	    width: -moz-max-content;
	    width: max-content
	}
	
	.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT,.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT .DJ_mSvGb16Vm2TCY_nub {
	    -ms-flex-align: center;
	    align-items: center;
	    background-color: var(--theme-main-bg-color);
	    display: -ms-flexbox;
	    display: flex
	}
	
	.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT .DJ_mSvGb16Vm2TCY_nub {
	    -ms-flex-pack: center;
	    border: .01rem solid var(--theme-color-line);
	    border-radius: .25rem;
	    color: var(--theme-text-color);
	    cursor: pointer;
	    font-size: .22rem;
	    justify-content: center;
	    min-height: .5rem;
	    min-width: 1rem;
	    overflow: hidden;
	    padding: 0 .1rem;
	    text-align: center;
	    white-space: nowrap
	}
	
	.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT .DJ_mSvGb16Vm2TCY_nub:not(:last-child) {
	    margin-right: .2rem
	}
	
	.RU9ifcibtBDTqaoRHkOJ .jcudJ3DGG2ThEXZWXkIT .DJ_mSvGb16Vm2TCY_nub.JzUfpgiRzooKmArhWe8e {
	    background-color: var(--theme-primary-color);
	    border: .01rem solid var(--theme-primary-color);
	    color: var(--theme-primary-font-color)
	}
	.SeAbj9DqXIhIurVLjTOf {
	    background-color: var(--theme-bg-color);
	    display: block
	}
	
	.SeAbj9DqXIhIurVLjTOf .FNuttToTMkScYCWLSpAX {
	    padding: 0 .2rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .FNuttToTMkScYCWLSpAX .DpnAIZWql0sJQ_AyNOE2 {
	    background-color: var(--theme-main-bg-color);
	    border-radius: .14rem;
	    -webkit-box-shadow: 0 .03rem .1rem 0 var(--theme-bg-shadow);
	    box-shadow: 0 .03rem .1rem 0 var(--theme-bg-shadow)
	}
	
	.SeAbj9DqXIhIurVLjTOf .EB65ojGhkVbrhClXwc3T {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    margin-top: .2rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .EB65ojGhkVbrhClXwc3T .jYmlq4MNh5J8KO375eTB {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-color: var(--theme-main-bg-color);
	    border-radius: .14rem;
		box-sizing: border-box;
	    -webkit-box-shadow: 0 .03rem .1rem 0 var(--theme-bg-shadow);
	    box-shadow: 0 .03rem .1rem 0 var(--theme-bg-shadow);
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    height: 1.5rem;
	    justify-content: center;
	    margin-bottom: .2rem;
	    padding: .2rem;
	    width: 2.24rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .EB65ojGhkVbrhClXwc3T .jYmlq4MNh5J8KO375eTB .Oxprin8vMs6tWsDhs0RF {
	    color: var(--theme-text-color-lighten);
	    font-size: .24rem;
	    margin-bottom: .2rem;
	    text-align: center
	}
	
	.SeAbj9DqXIhIurVLjTOf .EB65ojGhkVbrhClXwc3T .jYmlq4MNh5J8KO375eTB .vYuETMZ5uBHm1wD46BdH {
	    color: var(--theme-text-color-darken);
	    font-size: .34rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .EB65ojGhkVbrhClXwc3T .jYmlq4MNh5J8KO375eTB .vYuETMZ5uBHm1wD46BdH .currencyAmount {
	    font-size: .34rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .N7fdZkhB2FrpFTuNa4Vf {
	    font-size: .24rem;
	    height: .6rem;
	    padding: .15rem .2rem;
		box-sizing: border-box;
	    position: relative;
	    text-align: center;
	    width: 100%
	}
	
	.SeAbj9DqXIhIurVLjTOf .N7fdZkhB2FrpFTuNa4Vf:before {
	    border-color: var(--theme-text-color);
	    border-style: solid;
	    border-width: .01rem .01rem 0 0;
	    content: "";
	    display: block;
	    height: .15rem;
	    position: absolute;
	    right: .2rem;
	    top: 50%;
	    -webkit-transform: translate(-50%,-50%) rotate(45deg);
	    transform: translate(-50%,-50%) rotate(45deg);
	    width: .15rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd {
	    padding: 0 .2rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    font-size: .24rem;
	    justify-content: space-between
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB>div:first-child {
	    border-bottom: .01rem solid var(--theme-color-line);
	    color: var(--theme-text-color);
	    width: 100%
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB:not(:last-child) {
	    border-bottom: .01rem solid var(--theme-color-line)
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB .zRdyabQdPLLOQmV6vfWA {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    font-size: .22rem;
	    height: 1.2rem;
	    justify-content: center;
	    position: relative;
	    width: 33.3%
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB .zRdyabQdPLLOQmV6vfWA>span:first-child {
	    color: var(--theme-text-color-lighten);
	    margin-bottom: .15rem;
	    text-align: center
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB .zRdyabQdPLLOQmV6vfWA .currencyAmount {
	    color: var(--theme-text-color-darken);
	    font-size: .24rem
	}
	
	.SeAbj9DqXIhIurVLjTOf .aRAlpf3Aum4EXH0wnIwd .vzG9Liccdun0wlTHfCOB .zRdyabQdPLLOQmV6vfWA:not(:last-child):after {
	    background-color: var(--theme-color-line);
	    content: "";
	    height: .8rem;
	    position: absolute;
	    right: 0;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    width: .01rem
	}
	.avMNK8Qg8S6ELvNBn4SS{
		   
			-ms-flex-align: center;
			    -ms-flex-pack: justify;
			    align-items: center;
			    border-bottom: .01rem solid var(--theme-color-line);
			    -webkit-box-sizing: border-box;
			    box-sizing: border-box;
			    display: -ms-flexbox;
			    display: flex;
			    height: .6rem;
			    justify-content: space-between;
			    padding: 0 .2rem;
				 height: .8rem;
	}
	.avMNK8Qg8S6ELvNBn4SS>span:first-child {
	    color: var(--theme-text-color-darken);
	    font-size: .22rem;
	}
	.avMNK8Qg8S6ELvNBn4SS>span:first-child {
	    font-size: .24rem;
	}
	

</style>
