<template>
	<div class="Idioma-popup">
		<van-popup v-model="isDireto.DetalhesdaAposta" @close="$store.commit('publicFun',['isDireto',{
				DetalhesdaAposta:false,
			}])">
			<!-- {{isDireto}} -->
			<div class='ant-modal-content'>
				<div class="ant-modal-header">
					<div class='ant-modal-title' v-if="isDireto.type==1">Detalhes da aposta</div>
					<div class='ant-modal-title' v-if="isDireto.type==2">Subordinados de {{isDireto.user_id}}</div>
					<div class='ant-modal-title' v-if="isDireto.type==3">Subordinados de {{isDireto.user_id}}</div>
					<!--  -->
					<!-- <span @click="closeFun" class="ant-modal-close-x"><i aria-label="icon: close" class="anticon anticon-close ant-modal-close-icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" data-icon="close" fill="currentColor" aria-hidden="true" focusable="false" class=""><g id="comm_icon_x" transform="translate(-1209.5 -160.5)"><path id="Line_14" data-name="Line 14" d="M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z" transform="translate(1210.5 161.5)"></path><path id="Line_15" data-name="Line 15" d="M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z" transform="translate(1210.5 161.5)"></path></g></svg></i></span> -->
				</div>
				<div class='ant-modal-body'>
					<div class="ant-row-flex ant-row-flex-start C8cXiFHgN044ZoXZdLnO"
						style="display: flex;align-items: center;justify-content: space-between;padding-bottom: .2rem;">
						<!-- "start_time":"2024/07/07","end_time":"2024/08/07", -->
						<ant-select v-if="isDireto.type==1" :value="game_typeValue" :options="game_type" @change="_onChangeGame_type">
						</ant-select>
						<div class="ant-col">
							<label>Data</label><span
								class="dateTime">{{isDireto.start_time}}-{{isDireto.end_time}}</span>
						</div>
						<!--  -->
						<!-- <div class="ant-col"><label>{{$store.state.isDireto.type==2?'D. dos Outros':'D. Direto'}} </label><span class="currencyAmount">{{shareInfo.bet_total}}</span></div> -->
					</div>
					<div class="TBtGFT5INIot5sEUIm8w vantDataList">
						<van-empty image-size="2.1rem" v-if="!isDireto.list || !isDireto.list.length"  class="custom-image"
							:image="img_none_sj"
							:description="$t('description')" />
						<van-list :finished="finished"
							 v-model="loading"
							@load="onLoad">
							<div  v-if="isDireto.type==1" v-for="item in isDireto.list">
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="Iu2XDEUxSXVTmZ2ClDl5">
										<div class="dG0FpGdkHU5MgpFCLljd globalVipLevel">
											<p class="TmCsEwuUw4tM7cplmM6W">{{item.vip}}</p>
										</div>
										<div @click="copyContent(item.id)" class="vKDmG8Nz_Vcot47o6C7L globalInnerMain">
											<div class="Qu0jzrV6QQQRuKG_6idK">{{item.id}}</div><span
												class="PmwtECGo9wfGBMP0uy71 copyIcon Chb8LxYkjJj1HlLlsG5G">
												<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
											</span>
										</div>
									</div>
									<div class="ant-col ant-col-12"><label class="MlezEysRdS_J1OuFqOI4"
											style="    color: var(--theme-text-color-darken);">{{item.create_time}}</label>
									</div>
								</div>

								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Plataforma de jogo</label>
										<span class="currencyAmount">{{item.platform}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Nome do jogo</label>
										<span class="currencyAmount">{{item.game}}</span>
									</div>
								</div>

								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Apostas Válidas</label>
										<span class="currencyAmount">{{item.bet}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">V/D dos membros</label>
										<!-- <span class="currencyAmount">{{item.game}}</span> -->
										<span class="currencyAmount" :class="{
												  Q6NkNwL6l8CDPSdtrO2P:item.gain>0,
												  MCxdxRhxa0voqrCEptAa:item.gain<0,
											  }">{{item.gain_str}}</span>
									</div>
								</div>
							</div>
							<div  v-if="isDireto.type==2" v-for="item in isDireto.list">
								<!-- Subordinados Dele -->
								<!-- Apostas Válidas -->
								<!-- Total de V/D -->
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="Iu2XDEUxSXVTmZ2ClDl5">
										<div class="dG0FpGdkHU5MgpFCLljd globalVipLevel">
											<p class="TmCsEwuUw4tM7cplmM6W">{{item.vip}}</p>
										</div>
										<div @click="copyContent(item.id)" class="vKDmG8Nz_Vcot47o6C7L globalInnerMain">
											<div class="Qu0jzrV6QQQRuKG_6idK">{{item.id}}</div><span
												class="PmwtECGo9wfGBMP0uy71 copyIcon Chb8LxYkjJj1HlLlsG5G">
												<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
											</span>
										</div>
									</div>
									
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Subordinados Dele</label>
										<span class="currencyAmount"
										:class="{
											active:item.subordinate>0
										}"
										>{{item.subordinate}}</span>
									</div>
								</div>
								
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Apostas Válidas</label>
										<span class="currencyAmount active" >{{item.bet_total}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Nome do jogo</label>
										<span class="currencyAmount"
										:class="{
												  Q6NkNwL6l8CDPSdtrO2P:item.gain>0,
												  MCxdxRhxa0voqrCEptAa:item.gain<0,
											  }"
										>{{item.gain_str}}</span>
									</div>
								</div>
								
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4" style="color: var(--theme-text-color-darken);"> ({{item.bet_count}}Vezes)</label>
									</div>
								</div>
							</div>
							<div  v-if="isDireto.type==3" v-for="item in isDireto.list">
								<!-- Subordinados Dele -->
								<!-- Apostas Válidas -->
								<!-- Total de V/D -->
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="Iu2XDEUxSXVTmZ2ClDl5">
										<div class="dG0FpGdkHU5MgpFCLljd globalVipLevel">
											<p class="TmCsEwuUw4tM7cplmM6W">{{item.vip}}</p>
										</div>
										<div @click="copyContent(item.id)" class="vKDmG8Nz_Vcot47o6C7L globalInnerMain">
											<div class="Qu0jzrV6QQQRuKG_6idK">{{item.id}}</div><span
												class="PmwtECGo9wfGBMP0uy71 copyIcon Chb8LxYkjJj1HlLlsG5G">
												<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
											</span>
										</div>
									</div>
									
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Subordinados Dele</label>
										<span class="currencyAmount"
										:class="{
											active:item.subordinate>0
										}"
										>{{item.subordinate}}</span>
									</div>
								</div>
								
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Depósito</label>
										<span class="currencyAmount" >{{item.recharge_total}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Data de Cadastro</label>
										<span class="currencyAmount"
										>{{item.reg_time}}</span>
									</div>
								</div>
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Apostas Válidas</label>
										<span class="currencyAmount" >{{item.bet_total}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Data de login</label>
										<span class="currencyAmount"
										>{{item.login_time}}</span>
									</div>
								</div>
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Atual</label>
										<span class="currencyAmount" >{{item.online}}</span>
									</div>
									<!-- item.gain -->
									<div class="ant-col ant-col-12">
										<label class="MlezEysRdS_J1OuFqOI4">Estado</label>
										<span :class="{
											isRed:!item.status,
											isGreen:item.status
										}">{{item.status==1?'Normal':'Lock'}}</span>
									</div>
								</div>
								
							</div>
						</van-list>
						
						<div class="YKMs8a2WcRCsQOvOcRCY"  v-if="isDireto.type==1&&isDireto.list && isDireto.list.length" >
							<div class="ubmGlA1Nw3rv8HdRPACe">
								<div class="ant-row kVng2BT_nwrPgIkESGT5">
									<div class="ant-col ant-col-12"><span>Total de apostas válidas</span><span
											class="currencyAmount">{{isDireto.bet_total}}</span></div>
									<div class="ant-col ant-col-12"><span>Total de apostas</span><span>{{isDireto.bet_count}}</span></div>
									<div class="ant-col ant-col-12"><span>Total de V/D</span><span
											
											<span class="currencyAmount" :class="{
													  Q6NkNwL6l8CDPSdtrO2P:isDireto.gain>0,
													  MCxdxRhxa0voqrCEptAa:isDireto.gain<0,
												  }">{{isDireto.gain_str}}</span>
											</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			

			<div @click="$store.commit('publicFun',['isDireto',{
				DetalhesdaAposta:false,
			}])" class="QzRd3YqTnYxG8Latq8fW closeIcon"><img data-groups-id="my-img-1e7adda2-2e2a-446c-8fbb-cdfafd81c2c4"
					data-src-render="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					alt="." data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"></div>
		</van-popup>

	</div>
</template>

<script>
	// 
	import {
		shareSubordinateBetLog,
shareSubordinateWagers,
shareSubordinateStats
	} from "@/apis/modules/share.js";
	import {
		mapState
	} from 'vuex';
	export default {
		computed: {
			...mapState(['isDireto'])
		},
		props: {

		},
		data() {
			return {
				shareInfo: {},
				game_typeValue: "0",
				loading: false,
				fromType:1,
				finished: false,
				game_type: [{
						text: "Todos os Tipos",
						value: '0'
					},
					{
						text: "Slots",
						value: '1'
					},
					{
						text: "Pescaria",
						value: "2"
					}
				]
			}
		},
		mounted() {},
		methods: {
			onLoad() {
				this.loading=false;
				if(this.finished || this.isDireto.list.length<50){
					this.loading=false;
					return false;
				}
				this.fromType=this.fromType+1;
				this.shareSubordinateBetLog();
				// this.getBetLog();
			},
			_onChangeGame_type(e){
				this.game_typeValue=e[0];
				this.fromType=1;
				
				this.shareSubordinateBetLog(e);
			},
			shareSubordinateBetLog(e=[]){
				let formData={
					game_type:e[0]?e[0]:this.isDireto.game_type,
					page:this.fromType,
					type:this.isDireto.type,
					user_id:this.isDireto.user_id,
					pid: this.$store.state.vuex_token_head.user_id,
					"start_time":this.isDireto.start_time,
					"end_time":this.isDireto.end_time,
				}
				
				if(this.isDireto.type==1){
					shareSubordinateBetLog({
							...formData,
						}).then(({
							data
						})=>{
							this.loading=false;
							if(JSON.stringify(data.list)=='[]' || data.list.length<50){
								this.finished = true;
							}else{
								this.finished = false;
							}
							if(this.fromType==1){
								this.$store.commit('publicFun',['isDireto',{
									DetalhesdaAposta:true,
									type:'1',
									...data,
									...formData,
								}])
							}else{
								data.list=[...this.isDireto.list,...data.list]
								
								this.$store.commit('publicFun',['isDireto',{
									DetalhesdaAposta:true,
									type:'1',
									...isDireto,
									list:data.list,
									...formData,
								}])
							}
							
						})
				}else if(this.isDireto.type==2){
					shareSubordinateWagers({
						pid: this.isDireto.user_id,
						...formData,
					}).then(({
						data
					}) => {
						this.loading=false;
						if(JSON.stringify(data.list)=='[]' || data.list.length<50){
							this.finished = true;
						}else{
							this.finished = false;
						}
						if(this.fromType==1){
							this.$store.commit('publicFun',['isDireto',{
								DetalhesdaAposta:true,
								type:'1',
								...data,
								...formData,
							}])
						}else{
							data.list=[...this.isDireto.list,...data.list]
							
							this.$store.commit('publicFun',['isDireto',{
								DetalhesdaAposta:true,
								type:'1',
								...isDireto,
								list:data.list,
								...formData,
							}])
						}
					})
				}else{
						shareSubordinateStats({
							pid: this.isDireto.user_id,
							...formData,
						}).then(({
							data
						}) => {
							this.loading=false;
							if(JSON.stringify(data.list)=='[]' || data.list.length<50){
								this.finished = true;
							}else{
								this.finished = false;
							}
							if(this.fromType==1){
								this.$store.commit('publicFun',['isDireto',{
									DetalhesdaAposta:true,
									type:'1',
									...data,
									...formData,
								}])
							}else{
								data.list=[...this.isDireto.list,...data.list]
								
								this.$store.commit('publicFun',['isDireto',{
									DetalhesdaAposta:true,
									type:'1',
									...isDireto,
									list:data.list,
									...formData,
								}])
							}
						})
					
				}
				
				
				
			},
			closeFun() {
				// this.isShow=false;
				this.$emit('qingkOngs')
			},
			switchLang(e = 'en') {
				this.$i18n.locale = e;
				this._i18n.locale = e;
				// this.isShow=false;
				this.$emit('_remove')
				this.$vuex('_i18nLocale', e)
			},
		}

	}
</script>
<style>
	.TBtGFT5INIot5sEUIm8w .van-list>div {
		position: relative;
	}
</style>
<style scoped>
	.kVng2BT_nwrPgIkESGT5 {
	    font-size: .2rem;
	    height: .3rem;
	    line-height: .22rem;
	    margin: 0
	}
	.vantDataList .van-list>div .isGreen {
		color: var(--theme-secondary-color-success)
	}
	
	.vantDataList .van-list>div .isRed {
		color: var(--theme-secondary-color-error)
	}
	
	.YKMs8a2WcRCsQOvOcRCY {
	    -webkit-box-shadow: 0 -.03rem .2rem 0 rgba(0,0,0,.1);
	    box-shadow: 0 -.03rem .2rem 0 rgba(0,0,0,.1);
	    padding: .1rem 0;
		padding-bottom: 0.1rem;
	    width: 100%
	}
	
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 {
	    font-size: .2rem;
	    height: .3rem;
	    line-height: .22rem;
	    margin: 0
	}
	
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col {
	    margin-bottom: .1rem
	}
	
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col:last-child {
	    margin-bottom: 0
	}
	
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:first-child {
	    width: 50%
	}
	
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:nth-child(2) {
	    padding: 0 .1rem;
	    width: 49%
	}
	.currencyAmount.active{
		 border-bottom: .01rem solid var(--theme-primary-color);
		    color: var(--theme-primary-color);
		    cursor: pointer;
		    padding: 0 0 .02rem
	}
	 .YKMs8a2WcRCsQOvOcRCY .ubmGlA1Nw3rv8HdRPACe {
	    position: relative
	}
		
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 {
	    color: var(--theme-text-color);
	    font-size: .2rem;
	    height: .4rem;
	    line-height: .22rem;
	    margin-top: .2rem;
	    width: 100%
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col {
	    -ms-flex-pack: start;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: flex-start;
	    white-space: nowrap
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span {
	    white-space: pre-wrap
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span .ferAPHq9MnRtT0UGsS_r {
	    font-size: .2rem
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:first-child {
	    color: var(--theme-text-color-lighten);
	    overflow-y: hidden;
	    width: 49%
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:nth-child(2) {
	    width: 49%
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col:last-child>span:nth-child(2) {
	    color: var(--theme-secondary-color-finance)
	}
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span .ferAPHq9MnRtT0UGsS_r {
	    font-size: .22rem
	}
	.TBtGFT5INIot5sEUIm8w .van-list {
		padding: 0;
	}
	.YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col {
	    margin-bottom: .1rem
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col:last-child {
	    margin-bottom: 0
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:first-child {
	    width: 50%
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span:nth-child(2) {
	    padding: 0 .1rem;
	    width: 49%
	}
	
	 .YKMs8a2WcRCsQOvOcRCY .kVng2BT_nwrPgIkESGT5 .ant-col>span .ferAPHq9MnRtT0UGsS_r {
	    font-size: .22rem
	}
	.ant-col-12 {
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    display: block;
		    float: left;
	    width: 50% !important;
	}

	.Q6NkNwL6l8CDPSdtrO2P {
		color: var(--theme-secondary-color-error) !important;
	}

	.MCxdxRhxa0voqrCEptAa {
		color: var(--theme-secondary-color-success) !important;
	}

	.iSeiyC3bqps4IGGTXatc {
		background-color: var(--theme-secondary-color-error);
		color: var(--theme-secondary-color-error)
	}

	.TmTlgIIHiQR86nkpcl8M {
		color: var(--theme-secondary-color-finance);
		/* background-color:var(--theme-secondary-color-finance); */
	}

	.Q6NkNwL6l8CDPSdtrO2P1 {
		background-color: var(--theme-secondary-color-error);
	}

	.MCxdxRhxa0voqrCEptAa1 {
		background-color: var(--theme-secondary-color-success);
	}

	.iSeiyC3bqps4IGGTXatc1 {
		background-color: var(--theme-secondary-color-error);
		color: var(--theme-secondary-color-error)
	}

	.TmTlgIIHiQR86nkpcl8M1 {
		background-color: var(--theme-secondary-color-finance);
	}

	.ant-modal-content .mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
		font-size: .24rem;
		height: .6rem;
		line-height: .6rem;
	}

	.ant-modal-content {
		background-color: var(--theme-main-bg-color);
		border: thin solid;
		border-color: var(--theme-color-line);
		border-radius: .2rem;
		max-height: calc(100vh - .1rem);
		max-height: calc(var(--vh, 1vh)*100 - .1rem);
		overflow: auto;
		position: static;
		width: 7.1rem;
		padding: 0 0 .1rem 0;
	}

	.ant-modal-close-x {
		font-size: .2rem;
		height: .6rem;
		line-height: .6rem;
		color: var(--theme-text-color);
		width: .6rem;
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		font-size: .2rem;
	}

	.ant-modal-close:focus,
	.ant-modal-close:hover {
		color: rgba(0, 0, 0, .75);
		text-decoration: none
	}

	.Idioma-popup .van-popup {
		background-color: initial;
	}

	.ant-modal-content .ant-modal-header {
		background-color: var(--theme-main-bg-color);
		border-bottom: none;
		border: none;
		border-radius: .2rem .2rem 0 0;
		padding: .2rem .3rem
	}

	.ant-modal-body::after {
		display: none;
	}

	.ant-modal-content .ant-modal-header .ant-modal-title {
		color: var(--theme-text-color-darken);
		font-size: .3rem;
		font-weight: revert;
		line-height: .34rem;
		text-align: center
	}

	.ant-modal-content .ant-modal-body {
		padding: .2rem .2rem 0 .2rem;
		position: relative;
	}

	.ant-modal-content .ant-modal-body:after {
		background-color: var(--theme-color-line);
		content: "";
		height: .01rem;
		left: 0;
		position: absolute;
		top: 0rem;
		width: 100%
	}

	.XmFVAcOt7YCkuEJ1j2xA {
		border: thin solid var(--theme-color-line);
		border-radius: 100%;
		font-size: .18rem;
		height: .3rem;
		line-height: .33rem;
		width: .3rem
	}

	.Iu2XDEUxSXVTmZ2ClDl5 {
		display: flex;
		align-items: center;

	}

	._yZsg5Aq0active .cicle {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: var(--theme-primary-color);
		border: thin solid var(--theme-primary-color);
		color: var(--theme-primary-font-color);
		display: -ms-flexbox;
		display: flex;
		justify-content: center
	}

	._yZsg5Aq0active>span {
		color: var(--theme-primary-color)
	}

	.XmFVAcOt7YCkuEJ1j2xA i {
		opacity: 0
	}

	.XmFVAcOt7YCkuEJ1j2xA .imageNav-a {
		width: .18rem;
		color: var(--theme-primary-font-color);
		height: .18rem;
	}

	.QzRd3YqTnYxG8Latq8fW {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: none;
		border-radius: 50%;
		display: -ms-flexbox;
		display: flex;
		height: .64rem;
		justify-content: center;
		margin: 0 auto;
		margin-top: .2rem;
		width: .64rem
	}

	.QzRd3YqTnYxG8Latq8fW img {
		width: 100%;
		height: 100%;
	}

	.Tx8SM0ickmaT3XlvpBa3 {
		padding: .3rem 0
	}

	.Tx8SM0ickmaT3XlvpBa3 li {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem;
		justify-content: space-between;
		margin-bottom: .2rem;
		min-height: .26rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X {
		-ms-flex-align: start;
		align-items: start;
		border-top: thin dashed var(--theme-color-line);
		color: var(--theme-text-color-lighten);
		-ms-flex-direction: column;
		flex-direction: column;
		padding: .16rem 0 .3rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X img {
		height: 1.25rem;
		margin-top: .12rem;
		width: 1.25rem
	}

	.PmwtECGo9wfGBMP0uy71 {
		color: var(--theme-primary-color);
		cursor: pointer
	}

	.PmwtECGo9wfGBMP0uy71 svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem
	}

	.vKDmG8Nz_Vcot47o6C7L .Chb8LxYkjJj1HlLlsG5G {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		font-size: .24rem;
		margin-left: .1rem;
		position: relative;
		top: .02rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li:last-child {
		margin-bottom: 0
	}

	.Tx8SM0ickmaT3XlvpBa3 li>span:first-child {
		color: var(--theme-text-color-lighten);
		-ms-flex: 1;
		flex: 1;
		max-width: 40%
	}

	.Tx8SM0ickmaT3XlvpBa3 li>span:last-child {
		color: var(--theme-text-color-darken);
		max-width: 60%;
		word-break: break-all
	}

	.Tx8SM0ickmaT3XlvpBa3 li .ImHReXiC3qHaglpWgdTK {
		-ms-flex-item-align: start;
		align-self: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi img {
		border-radius: .05rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .JCvkz7A6x1jjaTlhnuDX {
		max-width: 3.3rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .PZ7EGYiPjnqzRGJ8oL6s {
		max-width: 2.7rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA {
		display: -ms-flexbox;
		display: flex;
		line-height: 1.2;
		max-width: 2.7rem;
		text-align: right;
		word-break: break-all
	}

	.Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA>span:first-child {
		max-width: 2.3rem;
		text-align: right
	}

	.Tx8SM0ickmaT3XlvpBa3 li .Md6HK8VKOeLdNOfHEVTY {
		color: var(--theme-primary-color);
		cursor: pointer;
		font-size: .27rem;
		margin-left: .1rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .XgqnfUsNuIo0CPXFCyLm {
		color: var(--theme-primary-color) !important;
		cursor: pointer
	}

	.Tx8SM0ickmaT3XlvpBa3 .ns96l6JACKUvG1AFxK8m {
		-ms-flex-align: start;
		align-items: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g {
		-ms-flex-align: start;
		align-items: start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>span {
		word-break: break-word !important
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>div {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		justify-content: center
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		height: 1.5rem;
		justify-content: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 .FKa0XIVD1gdhve7pK7sP {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border: .01rem solid var(--theme-primary-color);
		border-radius: .1rem;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		font-size: .16rem;
		height: .5rem;
		justify-content: center;
		margin-left: .3rem;
		text-align: center;
		width: 1.2rem
	}

	.Tx8SM0ickmaT3XlvpBa3 .VMmO1mBS3iM9ZHdoRrx9 {
		font-weight: 700
	}

	.mwRRRArq5gAZYhhvbvhg {
		-ms-flex-align: center;
		align-items: center;
		border-bottom: thin dashed var(--theme-color-line);
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: .3rem;
		padding-top: .16rem
	}

	.mwRRRArq5gAZYhhvbvhg .AwOlFgTYfgvrUiDuREQ3 {
		border-radius: 50%;
		height: 1.1rem;
		width: 1.1rem;
	}

	.mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
		font-size: .2rem;
		line-height: 1.2;
		margin: .2rem 0 .05rem
	}

	.mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .4rem;
		height: .58rem;
		justify-content: center;
		line-height: .58rem;
		position: relative;
		text-align: center;
		width: 100%
	}

	.mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp>span>span {
		font-size: .3rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		margin-bottom: .1rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ {
		color: var(--theme-secondary-color-error);
		display: inline-block;
		font-size: .24rem;
		margin: 0 .02rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ span {
		background-image: none
	}

	.mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 {
		color: var(--theme-text-color-lighten);
		font-size: .14rem;
		text-align: center
	}

	.mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 span {
		color: var(--theme-secondary-color-finance);
		margin-left: .05rem
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		text-align: center
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu p {
		line-height: .3rem
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu span {
		color: var(--theme-secondary-color-finance);
		margin-left: .05rem
	}

	.C8cXiFHgN044ZoXZdLnO,
	.C8cXiFHgN044ZoXZdLnO .ant-col {
		font-size: .2rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col label,
	.C8cXiFHgN044ZoXZdLnO label {
		color: var(--theme-text-color-lighten);
		margin-right: .05rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col span,
	.C8cXiFHgN044ZoXZdLnO span {
		color: var(--theme-text-color-darken)
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col span .suffix,
	.C8cXiFHgN044ZoXZdLnO span .suffix {
		padding: 0 .05rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col .dateTime,
	.C8cXiFHgN044ZoXZdLnO .dateTime {
		color: var(--theme-text-color-darken);
		font-size: .2rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col:first-child,
	.C8cXiFHgN044ZoXZdLnO:first-child {}


	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .TJ8NUYQ9fq36xPLTbHQH {
		left: 0;
		position: absolute;
		top: 0
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L .Qu0jzrV6QQQRuKG_6idK {
		color: var(--theme-text-color-darken);
		margin-right: .1rem;
		overflow: hidden;
		text-align: left;
		text-overflow: ellipsis
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L .Chb8LxYkjJj1HlLlsG5G {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		font-size: .24rem;
		margin-left: .1rem;
		position: relative;
		top: .02rem
	}

	.van-list>div .MlezEysRdS_J1OuFqOI4 {
		color: var(--theme-text-color-lighten);
		margin-right: .05rem
	}

	.van-list>div .currencyAmount,
	.van-list>div .suffix {
		color: var(--theme-text-color-darken)
	}

	.vantDataList {
		font-size: .2rem;
		height: 8.8rem
	}

	.ant-row-flex-space-between {
		-ms-flex-pack: justify;
		display: flex;
		justify-content: space-between
	}
</style>
