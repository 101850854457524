import { render, staticRenderFns } from "./canReceive.vue?vue&type=template&id=f51d3cba&scoped=true"
import script from "./canReceive.vue?vue&type=script&lang=js"
export * from "./canReceive.vue?vue&type=script&lang=js"
import style0 from "./canReceive.vue?vue&type=style&index=0&id=f51d3cba&prod&lang=css"
import style1 from "./canReceive.vue?vue&type=style&index=1&id=f51d3cba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f51d3cba",
  null
  
)

export default component.exports