<template>
	<div class=" ant-select-enabled"  >
		<div class="disabled" v-if="disabled"></div>
		<!-- active -->
		<div class="TGxvcgbTuwrxQwhlOYhR" @click="isShow=!isShow">
			<!-- {{dados[tabIndex]||'--'}} -->
			<div class="left-icon">
				<slot name="left-icon"></slot>
			</div>
			<span
			v-if="dados[modelvalue].name"
			class="GpjPIjRFCDUkDBOPI0Mu">{{dados[modelvalue].name}}</span>
			<span
			v-else-if="dados[modelvalue]"
			class="GpjPIjRFCDUkDBOPI0Mu">{{dados[modelvalue]}}</span>
			
		</div>
		<i aria-label="icon: down" class="anticon anticon-down ant-select-arrow-icon"><svg
				xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.969 21.998" data-icon="down"
				fill="currentColor" aria-hidden="true" focusable="false" class="">
				<path id="comm_icon_fh"
					d="M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z"
					transform="translate(-102.181 2212.68) rotate(-90)"></path>
			</svg></i>
			<div class="ant-select-dropdown"   :style="{
				display:isShow?'block':'none'
			}">
				<ul class="ant-select-dropdown-menu">
					<li class="ant-select-dropdown-menu-item" 
					  
					  @click="emit_click(item,index)" v-for="(item,index) in dados" >
						<div class="TGxvcgbTuwrxQwhlOYhR">
								<span class="ELiiCJlHWQtodyrr5DLP"
								:style="{
									color:modelvalue==index?'var(--theme-primary-color) !important':''
								}"
								v-if="item.name"
								>{{item.name}}</span>
								<span class="ELiiCJlHWQtodyrr5DLP"
								v-else-if="item"
								:style="{
									color:modelvalue==index?'var(--theme-primary-color) !important':''
								}"
								>{{item}}</span>
						</div>
					</li>
				</ul>
			</div>
	</div>
</template>

<script>
	export default{
		props:{
			disabled:{
				type: Boolean,
				default: false,
			},
			dados:{
				type: [Array],
				default: ()=>{
					return []
				},
			},
			modelvalue:{
				type:[Number,String],
				default: ()=>{
					return 0
				},
			}
		},
		methods:{
			emit_click(item,index){
				this.$emit('_click',item,index)
				this.isShow=false;
			}
		},
		data() {
			return{
				isShow:false,
				tabIndex:0,
			}
		},
		
	}
</script>

<style scoped>
	.disabled{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
	}
	.ant-select-enabled {
		background-color: var(--theme-main-bg-color);
		    
		border-radius: .14rem;
		width: 100%;
		box-sizing: border-box;
		
		border: 1px solid var(--theme-color-line);
		font-size: .22rem;
		height: .7rem;
		position: relative;
		line-height: .7rem;
		text-indent: .05rem;
		padding-left: .2rem;
		padding-right: .55rem;
		border: solid .02rem;
		border-color: var(--theme-color-line);
	}
	.ant-select-enabled:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important;
		outline: 0
	}

	.TGxvcgbTuwrxQwhlOYhR {
		display: flex;
		align-items: center;
	}

	.TGxvcgbTuwrxQwhlOYhR>span:first-of-type {
		margin-left: .2rem
	}

	.TGxvcgbTuwrxQwhlOYhR span {
		font-size: .22rem;
		color: var(--theme-text-color-lighten);
	}

	.ant-select-enabled.active {
		border-color: var(--theme-ant-primary-color-13);
		outline: 0
	}

	.ant-select-arrow-icon {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: inherit;
		color: rgba(0, 0, 0, .25);
		display: inline-block;
		font-size: 12px;
		font-style: normal;
		line-height: 0;
		line-height: 1;
		margin-top: -6px;
		position: absolute;
		right: 11px;
		text-align: center;
		text-transform: none;
		top: 50%;
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		vertical-align: -.125em
	}


	.ant-select-arrow-icon.anticon {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
	}
	.ant-select-dropdown {
	    background-color: var(--theme-main-bg-color);
	    border: .01rem solid var(--theme-color-line) !important;
	    border-radius: .1rem;
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
	    box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 100;
	}
	.ant-select-dropdown .cl {
		color: var(--theme-primary-color);
	}
	.ant-select-dropdown-menu{
		    max-height: 5.1rem;
		    padding: .14rem 0;
			    overflow: auto !important;
	}
	.ant-select-dropdown .ant-select-dropdown-menu-item {
	    font-size: .24rem;
	    line-height: .8rem;
	    min-height: .8rem;
	    padding: 0 .2rem;
	}
</style>

	

