<template>
	<div class=" ant-select-enabled" :class="{
			active:$attrs.active
		}">
		<div class="disabled" v-if="disabled"></div>
		<!-- active -->
		<!-- active -->
		<div class="TGxvcgbTuwrxQwhlOYhR" @click="isShow=!isShow">
			<img src="./../../assets/icons/images/icon_normal_pix.png" alt="."
				class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" basescale="0.8"
				style="width: 0.48rem; height: 0.48rem;"><span
				class="GpjPIjRFCDUkDBOPI0Mu">PIX</span><span>(****{{$attrs.contaDeLevantamentoFromDta.account_cpf ? $attrs.contaDeLevantamentoFromDta.account_cpf.slice('5') : ''}})</span><span
				class="ELiiCJlHWQtodyrr5DLP">{{$attrs.contaDeLevantamentoFromDta.account_type}}</span>
		</div>
		<div v-if="$attrs.active" class="_ojhUxKWGyFdJIV2Lodx subscript" style="display: flex;">
			<svg-icon className="imageWrap"
				style="color: var(--theme-filter-active-color);"
				icon-class="comm_img_corner--svgSprite:all" />
			<svg-icon className="duihap" style="color: var(--theme-web_filter_gou);"
				icon-class="comm_icon_gou--svgSprite:all" />
		</div>
		<i v-if="!$attrs.active" aria-label="icon: down" class="anticon anticon-down ant-select-arrow-icon"><svg
				xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.969 21.998" data-icon="down"
				fill="currentColor" aria-hidden="true" focusable="false" class="">
				<path id="comm_icon_fh"
					d="M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z"
					transform="translate(-102.181 2212.68) rotate(-90)"></path>
			</svg></i>
		<div class="ant-select-dropdown" :style="{
				display:isShow?'block':'none'
			}">
			<ul class="ant-select-dropdown-menu">
				<li class="ant-select-dropdown-menu-item" @click="isShow=!isShow">
					<div class="TGxvcgbTuwrxQwhlOYhR">
						<img src="./../../assets/icons/images/icon_normal_pix.png"  alt="."
							class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" basescale="0.8"
							style="width: 0.48rem; height: 0.48rem;">
						<span class="cl GpjPIjRFCDUkDBOPI0Mu">PIX</span>
						<span class="cl">(****{{$attrs.contaDeLevantamentoFromDta.account_cpf.slice('5')}})</span><span
							class="ELiiCJlHWQtodyrr5DLP">{{$attrs.contaDeLevantamentoFromDta.account_type}}</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			dados: {
				type: [Array],
				default: () => {
					return []
				},
			}
		},
		data() {
			return {
				isShow: false
			}
		}
	}
</script>

<style scoped>
	._ojhUxKWGyFdJIV2Lodx{
		bottom: -.01rem;
		display: block;
		height: .3rem;
		line-height: .3rem;
		overflow: hidden;
		position: absolute;
		z-index: 999;
		right: -.01rem;
		width: .3rem;
	}

	._ojhUxKWGyFdJIV2Lodx .imageWrap{
		width: 100% !important;
		position: absolute;
		height: 100% !important;
	}
	._ojhUxKWGyFdJIV2Lodx .duihap{
		height: .096rem;
	}
	._ojhUxKWGyFdJIV2Lodx .duihap {
	    bottom: .04rem;
	    color: var(--theme-active-gou-color);
	    color: var(--theme-web_filter_gou);
	    height: .096rem;
	    position: absolute;
	    right: .04rem;
	    width: .125rem
	}
	._ojhUxKWGyFdJIV2Lodx .duihap{
	}
	.disabled {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.ant-select-enabled {
		background-color: var(--theme-main-bg-color);

		border-radius: .14rem;
		width: 100%;
		box-sizing: border-box;

		border: 1px solid var(--theme-color-line);
		font-size: .22rem;
		height: .7rem;
		position: relative;
		line-height: .7rem;
		text-indent: .05rem;
		padding-left: .2rem;
		padding-right: .55rem;
		border: solid .02rem;
		border-color: var(--theme-color-line);
	}

	.ant-select-enabled:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important;
		outline: 0
	}

	.ant-select-enabled.active {
		border-color: var(--theme-ant-primary-color-13);
	}

	.TGxvcgbTuwrxQwhlOYhR {
		display: flex;
		align-items: center;
	}

	.TGxvcgbTuwrxQwhlOYhR>span:first-of-type {
		margin-left: .2rem
	}

	.TGxvcgbTuwrxQwhlOYhR span {
		font-size: .22rem;
		color: var(--theme-text-color-lighten);
	}

	.ant-select-enabled.active {
		border-color: var(--theme-ant-primary-color-13);
		outline: 0
	}

	.ant-select-arrow-icon {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: inherit;
		color: rgba(0, 0, 0, .25);
		display: inline-block;
		font-size: 12px;
		font-style: normal;
		line-height: 0;
		line-height: 1;
		margin-top: -6px;
		position: absolute;
		right: 11px;
		text-align: center;
		text-transform: none;
		top: 50%;
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		vertical-align: -.125em
	}


	.ant-select-arrow-icon.anticon {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
	}

	.ant-select-dropdown {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line) !important;
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		overflow: hidden;
		position: absolute;
		left: 0;
		z-index: 999;
		width: 100%;
	}

	.ant-select-dropdown .cl {
		color: var(--theme-primary-color);
	}

	.ant-select-dropdown-menu {
		max-height: 5.1rem;
		padding: .14rem 0;
		overflow: auto !important;
	}

	.ant-select-dropdown .ant-select-dropdown-menu-item {
		font-size: .24rem;
		line-height: .8rem;
		min-height: .8rem;
		padding: 0 .2rem;
	}
</style>
