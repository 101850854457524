import { render, staticRenderFns } from "./TarefasDeApostas.vue?vue&type=template&id=4559edde&scoped=true"
import script from "./TarefasDeApostas.vue?vue&type=script&lang=js"
export * from "./TarefasDeApostas.vue?vue&type=script&lang=js"
import style1 from "./TarefasDeApostas.vue?vue&type=style&index=1&id=4559edde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4559edde",
  null
  
)

export default component.exports