<template>
	<span :class="`ant-input-search ant-input-affix-wrapper z1lz8Qo1SWzxGDCoTLYg ${className}`">
		<input :placeholder="placeholder" type="text" v-bind="$attrs"
	    v-on="$listeners" class="ant-input" v-model="vModel" >
		<span class="ant-input-suffix" @click="search">
			<i aria-label="icon: search" tabindex="-1" class="anticon anticon-search ant-input-search-icon">
				<svg viewBox="64 64 896 896" data-icon="search" width="1em" height="1em" fill="currentColor"
					aria-hidden="true" focusable="false" class="">
					<path
						d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
					</path>
				</svg>
			</i>
		</span>
	</span>
</template>

<script>
	export default {
		props: {
			placeholder: {
				type: String,
				default: ''
			},
			className:{
				type: String,
				default: ''
			},
			vModel:{
				type:[Number,String],
				default: ''
			}
		},
		data() {
			return {
				value: '',
			};
		},
		methods: {
			onSearch(val) {
				//Toast(val);
			},
			search(e){
				this.$emit('search',this.vModel)
			},
			onCancel() {
				//Toast('取消');
			},
		},
	};
</script>
<!-- <style src="./../../assets/public/ant.css"></style> -->
<style scoped>
	.ant-input {
		-webkit-font-feature-settings: "tnum";
		font-feature-settings: "tnum";
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: rgba(0, 0, 0, .65);
		display: inline-block;
		font-size: 14px;
		font-variant: tabular-nums;
		height: 32px;
		line-height: 1.5;
		list-style: none;
		margin: 0;
		padding: 4px 11px;
		position: relative;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%
	}
	input::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */
		 color: var(--theme-text-color-placeholder)
	}
	input:-moz-placeholder{    /* Firefox版本4-18 */
		 color: var(--theme-text-color-placeholder)
	}              
	input::-moz-placeholder{    /* Firefox版本19+ */
		color: var(--theme-text-color-placeholder)
	}              
	input:-ms-input-placeholder{   /* IE浏览器 */
		color: var(--theme-text-color-placeholder)
	}        
	.z1lz8Qo1SWzxGDCoTLYg {
		position: relative;
		display: inline;
	}

	.z1lz8Qo1SWzxGDCoTLYg .ant-input-suffix {
		position: absolute;
		right: .3rem;
		top: 50%;
		margin-top: -.13rem;
	}

	/*  */
	.z1lz8Qo1SWzxGDCoTLYg {
		width: 2rem
	}

	.ant-input-suffix {
		background-color: var(--theme-main-bg-color);

	}

	.z1lz8Qo1SWzxGDCoTLYg .ant-input-search-icon {
		font-size: .26rem;
		width: .26rem;
		height: .26rem;
	}

	.z1lz8Qo1SWzxGDCoTLYg input {
		font-size: .2rem;
		height: .5rem;
		/* width: 2rem */
	}

	.z1lz8Qo1SWzxGDCoTLYg .ant-input-search-icon {
		color: var(--theme-primary-color);
	}

	.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important
	}

	.ant-input-affix-wrapper {
		-webkit-font-feature-settings: "tnum";
		font-feature-settings: "tnum";
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: rgba(0, 0, 0, .65);
		display: inline;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		width: 100%
	}

	.z1lz8Qo1SWzxGDCoTLYg input {
		border-radius: .5rem;
		color: var(--theme-text-color-darken);
		font-size: .18rem;
		height: .5rem;
		/* width: 2.2rem */
	}

	.z1lz8Qo1SWzxGDCoTLYg .ant-input-suffix {
		background-color: transparent;
	}

	.z1lz8Qo1SWzxGDCoTLYg .ant-input-search-icon {
		font-size: .26rem
	}

	html .ant-input,
	html .ant-select-selection {
		background-color: var(--theme-main-bg-color);
		border-color: var(--theme-color-line);
		color: var(--theme-text-color-lighten)
	}
</style>
