<template>
    <div class="maxMaxth evebt">
        <div class="_QHPzkqbnIhdrHoFHZU1">
            <div class="FNothGJnr_XkMpVC9saQ">
                <span class="CBnmKFFy5fD3SQnxH3g9">{{ $t('_VIP._NívelAtual') }}</span>
                <div class="JlCRc6ttKGcNUyDwFygw reward-mixins__levelImage l2ogUtOtFSdH0ER0ochY"
                    style="flex: 0 0 1rem; width: 1rem; height: 1rem; font-size: 0.363636rem;">
                    <img
					v-lazy="formDelList.backgroud"
                    style="width: 1rem; height: 1rem;">
                    <img
					v-lazy="formDelList.icon"
                    class="goDRiiBsuEuXD3W1NphN" 
                    style="width: 1rem; height: 1rem;">
                    <span :data-text="formDelList.level">{{formDelList.level}}</span>
                </div>
                <div class="wVdHCegt0XdSulmA2Baj Fp0NS_G0kOgjuJJrHtPc reward-mixins__progressContent">
                    <div class="EP7cYZQjy3x8bePovMwY reward-mixins__progressNeed">
                        <span>{{ $t('_VIP._Restantes') }}<i>{{formDelList.next_level_name}}</i></span>
                        <span>{{ $t('_VIP._Apostemais') }} <strong>{{formDelList.upneed_bet}}</strong></span>
                    </div>
                   
                    <div class="yTrC9_SkCM0s2UT24Rop">
                        <van-button type="primary" size="small">{{ $t('_VIP._ResgatarTudo') }}</van-button>
                        <van-button @click="openUrl('records')" type="primary" size="small">{{ $t('_VIP._Histórico') }}</van-button>
                    </div>
                </div>
            </div>

            <div  class="ejJ7KgJPi3aEwhNsCfew theme-box-shadow">
                <div class="oWOki6dTquZVWAx0lScK">{{  $t('_VIP._ListadeníveisVIP')}}</div>
                <div class="OxGvwfHXXQVNNNoH8gV2">
                    <van-tabs v-model="activeName" 
                    @click="routerFun" 
                    :lineWidth="(activeLength*6+'px')"
                    animated
                    line-height="2px"
                    background="transparent" 
                    :ellipsis="false"
                    title-active-color="var(--theme-primary-color)"
                    title-inactive-color="var(--theme-text-color-darken)"
                    color="var(--theme-primary-color)">
                    <van-tab  title-style="font-size: 0.22rem;" v-for="(item,index) in tabList"  :title="$t(item.name)" :name="index" :key="index" >
						 <component  :Nvel_ss.sync="Nvel_ss" :is="item.content" :formDelList="formDelList" :item="item"></component>
                    </van-tab>
                 </van-tabs>
           
                </div>
            </div>
            <div class="ejJ7KgJPi3aEwhNsCfew theme-box-shadow" style="padding-bottom: 1.2rem;">
                <div class="oWOki6dTquZVWAx0lScK">{{ $t('_VIP._InstruçõessobreregrasVIP') }}</div>
                <div class="FFVTf32mKWROQCq1OHEb">
                    1.Padrão de promoção: atenda aos requisitos da promoção VIP (ou seja, a recarga ou apostas eficazes podem atender às condições), você pode avançar para o nível VIP correspondente e obter o bônus de promoção correspondente.O bônus pode ser recebido de tempos em tempos; <br>
2.Salário diário: Se a recarga diária e as apostas válidas atenderem aos requisitos salariais diários do nível atual, você poderá obter o bônus salarial diário correspondente. Se você avançar para vários níveis consecutivos, só poderá obter o bônus salarial diário do atual nível.O bônus pode ser recebido de tempos em tempos; <br>
3.Salário Semanal: Se a recarga semanal e as apostas válidas atenderem ao nível atual de requisitos salariais semanais, você poderá obter o bônus salarial semanal correspondente. Se você avançar para vários níveis consecutivos, poderá obter apenas o nível atual de bônus salarial semanal.O bônus pode ser recebido de tempos em tempos; <br>
4.Lulu mensal: recarga mensal e apostas efetivas para atender ao nível atual do Lulu mensal, e você pode obter o bônus de prêmio mensal correspondente.O bônus pode ser recebido de tempos em tempos; <br>
5.Tempo de Expiração da Recompensa: O bônus recebido fica disponível por %d dias. Se não for resgatado ativamente durante esse período, ele será automaticamente creditado na conta. Por exemplo: se uma recompensa for obtida em 1º de janeiro e retida por %d dias, será automaticamente creditada na conta em %d de janeiro às 00:00:00. <br>
6.Instruções para auditoria: o bônus VIP oferecido pode ser levantado apenas após o cumprimento do requisito de rollover 1x (ou seja, auditoria, apostas ou apostas válidas), independentemente da plataforma em que joga;<br>
7.Declarações: Esta função está limitada às operações normais dos titulares de contas. É proibido alugar contas, efetuar apostas sem risco (apostas com contas diferentes, swiping mútuo e swiping de odds baixas), arbitragem viciosa, utilizar plug-ins, robôs, exploração de acordos, lacunas, interfaces, controlo de grupo ou outros meios técnicos de participação; caso contrário, uma vez provado, a plataforma tem o direito de proibir os membros de iniciar sessão, suspender a utilização do nosso website, e confiscar o bônus e os ganhos indevidos, sem qualquer aviso especial; <br>
8.Instruções: Ao reclamar o bônus VIP, considera-se que os membros aceitam e cumprem as regras correspondentes. A fim de evitar diferenças na compreensão do texto, a plataforma reserva o direito final de interpretar esta atividade. <br>
                </div>
            </div>
        </div>
        <!-- Nvel_ss -->
        <ant-modal :isModel.sync="Nvel_ss"
			:content="$t('_VIP._LembreteConter')"
			:title="$t('_VIP._Lembrete')"
		></ant-modal>

     </div>
  </template>
  
  <script>

  
  // vipDetail
  import {vipDetail} from "@/apis/modules/game.js"
  import Aumento  from "./Aumento.vue"
  import nusSemanal  from "./BônusSemanal.vue"
  import nusMensal  from "./BônusMensal.vue"
  import PriviVIP from "./PriviVIP.vue"
 
  export default {
   name: 'vip',
   components: {
   },
   data(){
     return {
        activeName:0,
        activeLength:25,
        Nvel_ss:false,
        tabList:[
                {
                    name:'popup._BônusDeAumentoDeNível',
                    route:"event",
					content:Aumento,
					
                },
                {
                    name:'popup._BônusSemanal',
                    route:"vip",
					content:nusSemanal
                },
                {
                    name:'popup._BônusMensal',
                    route:"canReceive",
					content:nusMensal,
                },
                {
                    name:'popup._PrivilégioVIP',
                    route:"yuebao",
					content:PriviVIP
                }
            ],
		formDelList:{}
     }
   },
   created() {
	 this.$store.commit('sLoading',true)
   	this.vipDetail()
   },
   methods:{
	 vipDetail(){
		vipDetail({}).then(({data,code})=>{
			this.formDelList=data;
			this.$store.commit('sLoading',false)
		}) 
	 },
     tabFun(e){
       this.$store.commit('active',e)
     },
     routerFun(e,l){
        this.activeLength=l.length;
     },
     stickyScroLL(e){
     }
   }
  }
  </script>
  <style>
.ejJ7KgJPi3aEwhNsCfew .van-tabs__wrap--scrollable{
    border-bottom: .01rem solid var(--theme-color-line);
    margin-bottom: .2rem;
}</style>
  <style scoped>
  ._QHPzkqbnIhdrHoFHZU1 {
    padding: 0 .2rem;
    margin-top: .2rem;
}
.yTrC9_SkCM0s2UT24Rop {
    -ms-flex-align: end;
    align-items: flex-end;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}
  .FNothGJnr_XkMpVC9saQ{
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border-radius: .12rem;
    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
    box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: .45rem .2rem .25rem;
    padding: .2rem;
    position: relative;
  }
  .JlCRc6ttKGcNUyDwFygw,.NPzDoGC69c0JdVxoaQso,.l2ogUtOtFSdH0ER0ochY {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    text-align: center
}
.JlCRc6ttKGcNUyDwFygw img,.NPzDoGC69c0JdVxoaQso img,.l2ogUtOtFSdH0ER0ochY img {
    position: absolute
}
.JlCRc6ttKGcNUyDwFygw span:before,.NPzDoGC69c0JdVxoaQso span:before,.l2ogUtOtFSdH0ER0ochY span:before {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f7ea94),color-stop(51%,#e5b952),to(#ce9510));
    background-image: linear-gradient(180deg,#f7ea94 0,#e5b952 51%,#ce9510);
    background-size: cover;
    content: attr(data-text);
    height: 100%;
    left: 0;
    position: absolute;
    text-shadow: none;
    top: 0;
    width: 100%;
    z-index: 0
}

.JlCRc6ttKGcNUyDwFygw span,.NPzDoGC69c0JdVxoaQso span,.l2ogUtOtFSdH0ER0ochY span {
    color: transparent;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    position: relative;
    text-shadow: 0 .01rem 0 rgba(0,0,0,.4)
}
  .FNothGJnr_XkMpVC9saQ .CBnmKFFy5fD3SQnxH3g9 {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: var(--theme-secondary-color-error);
    border-radius: .08rem .08rem .08rem 0;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    font-size: .18rem;
    height: .3rem;
    justify-content: center;
    left: 0;
    padding: 0 .06rem;
    position: absolute;
    top: 0;
    z-index: 1
}

.FNothGJnr_XkMpVC9saQ .JlCRc6ttKGcNUyDwFygw {
    margin: 0 .2rem 0 0
}
.FNothGJnr_XkMpVC9saQ .JlCRc6ttKGcNUyDwFygw {
    margin-top: .2rem
}.yTrC9_SkCM0s2UT24Rop button:first-child {
    margin-bottom: .2rem;
    background-color: var(--theme-disabled-bg-color);
    border-color: var(--theme-disabled-bg-color);
    color: var(--theme-disabled-font-color)
}
.yTrC9_SkCM0s2UT24Rop button {
    border-radius: .1rem;
    font-size: .16rem;
    height: .4rem;
    line-height: .18rem;
    padding: 0 .05rem;
    width: 1rem
}


 .yTrC9_SkCM0s2UT24Rop button {
    height: .5rem;
    width: 1.2rem;
}
.wVdHCegt0XdSulmA2Baj.Fp0NS_G0kOgjuJJrHtPc {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}
.EP7cYZQjy3x8bePovMwY {
    font-size: .24rem;
    line-height: .26rem;
    margin-bottom: 0rem;
    padding-top: .2rem;
    color: var(--theme-text-color-lighten);
}
.EP7cYZQjy3x8bePovMwY span:first-child {
    display: block
}
.FNothGJnr_XkMpVC9saQ .EP7cYZQjy3x8bePovMwY i {
    color: var(--theme-secondary-color-error);
    font-weight: 700;
    margin: 0 .05rem
}
.FNothGJnr_XkMpVC9saQ .EP7cYZQjy3x8bePovMwY strong {
    color: var(--theme-text-color-darken)
}
.XUsRryuA9IC2zOJlMtTr {
    text-transform: capitalize;
}
.ejJ7KgJPi3aEwhNsCfew {
    border-radius: .1rem;
    color: var(--theme-text-color-darken);
    font-size: .24rem;
    margin: .2rem 0;
    text-align: left;
    font-size: .3rem;
}.ejJ7KgJPi3aEwhNsCfew .oWOki6dTquZVWAx0lScK {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    line-height: auto;
    padding: .2rem .2rem 0;
    padding-left: 0px;
}
.ejJ7KgJPi3aEwhNsCfew .OxGvwfHXXQVNNNoH8gV2 {
    -ms-overflow-style: none;
    border-bottom: .01rem solid var(--theme-color-line);
    min-width: 100%;
    scrollbar-width: none;
}

.FFVTf32mKWROQCq1OHEb {
    font-size: .22rem;
    line-height: 1.64;
    border-top: .01rem solid var(--theme-color-line);
    color: var(--theme-text-color);
    margin-top: .2rem;
    padding: .2rem;
    text-transform: none;
    white-space: break-spaces;
    word-break: break-all;
}
.KkRUNU5i1X4v4833bPfs {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: .14rem;
    justify-content: center
}
 ._2lvGimPeAnoEFiUw_fTw {
    position: relative;
    width: 3.1rem;
    margin-top: .1rem;
}
 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner {
    background-color: var(--theme-text-color-placeholder);
    border-radius: .2rem
}
 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner .ant-progress-bg {
    background-color: var(--theme-vip-progress-bg-color);
    border-radius: .2rem !important;
    height: .2rem !important;
}
._2lvGimPeAnoEFiUw_fTw>span {
    -webkit-background-clip: text!important;
    color: #fff;
    font-size: .16rem;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    top: 60%;
    transform: translate(-50%,-58%);
    white-space: nowrap;
    width: 94%
}

  </style>
  