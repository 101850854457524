<template>
	<div class="mine_conten"
	style="padding-bottom: 1.5rem;"
		:style="`background-image:url(${coloImgactivity});background-size: 100%;background-position: 0px 0px;`">
<!-- user_avatar_image -->
		<div class="CToeIaCnZ7Jr3D1XKTxw">
			<header class="DiNiMDlhVGqZnjLoStS4 PIj1PfbzHvacLB3SeEjQ">
				<div class="So_waOZgg7qEl0wURIQz" @click="openUrl('notice')" >
					<div class="blXLPejbalUrq5IycYVR" style="width: 0.4rem; height: 0.4rem;">
						<svg-icon className="imageWrap" icon-class="style_1_icon_top_kf--svgSprite:all" />
					</div><span class="header-item-label">{{ $t('mine._Suporte') }}</span>
				</div>
				<div class="So_waOZgg7qEl0wURIQz" @click="openUrl('notice',{active:1})">
					<div class="HY5zlQQwDl4OlzKNd0jr">
						<div class="blXLPejbalUrq5IycYVR" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageWrap" icon-class="style_1_icon_top_xxyd--svgSprite:all" />
						</div>
					</div>
					<span class="header-item-label">{{ $t('mine._Mensagens') }}</span>
				</div>
				<div class="So_waOZgg7qEl0wURIQz"  @click="openUrl('setting')" >
					<div class="blXLPejbalUrq5IycYVR" style="width: 0.4rem; height: 0.4rem;">
						<div class="blXLPejbalUrq5IycYVR" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageWrap" icon-class="style_1_icon_top_grzl--svgSprite:all" />
						</div>
					</div><span class="header-item-label">{{ $t('mine._Dados') }}</span>
					
				</div>
			</header>
			<div class="SVLWJ4WJI54O4lWBc7yk">
				<div class="P_VF1DJcPs_q4ieRYz7n"
					style="width: 0.88rem; height: 0.88rem; background-position: 0px 0px;  background-size: 100% 100%;border-radius: 50%;"
						:style="{
							backgroundImage: `${$store.state.config.user_avatar_image}`
						}"
					>
					<img style="width: 100%;height: 100%;border-radius: 50%;" :src="$store.state.config.user_avatar_image" alt="">
				</div>
				<div class="zvrVqQuEWSzu7Nsavegl">
					<p class="NeL_d0h5i7Kr9xdDCTty"><span
							class="B8Z5jNPFB44UUahGOFBH EpCtJEz_E_WMlMB6HeaI">{{ $t('mine._Conta') }}:</span><span
							class="PIj1PfbzHvacLB3SeEjQ">{{userInfo.user_name}}</span></p>
					<div class="Fn6L7w5ZEXiwo2fTNrRn" >
						<p class="glbY5bkp9x7KTYOGrdBC" style="" ><span
								class="B8Z5jNPFB44UUahGOFBH EpCtJEz_E_WMlMB6HeaI">ID:</span><span
								class="PIj1PfbzHvacLB3SeEjQ">{{userInfo.id}}</span>
						<div @click="copyContent(userInfo.id)" class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0 kot86ytTGgQsl2K_lpQ5"
							style="width: 0.28rem; height: 0.28rem;">
							<svg-icon className="imageWrap" icon-class="style_1_icon_copy--svgSprite:all" />
						</div>
						</p><span class="DTiaFYH2mnNOHXa8KLF0"></span>
						<div class="glbY5bkp9x7KTYOGrdBC">
							<i data-id="sprite_main_flag_1x1_Brazil" aria-hidden="true" focusable="false" class=""
								:style="`display: inline-block; position: relative; width: 0.34rem; height: 0.34rem; background-image: url(${_coloImg}); background-position: -8.11143rem -3.16929rem; background-size: 8.81571rem 8.60321rem;`"></i>
							<div class="vy918omw_N7rBkJ235XX IMl873FTgqqNlSitFJrT iyrfQ72Vih_uHUFOsiR4 currencyInfoBox main VOgj9FBCnkozjr2NA7rl"
								style="--row-align: center; --mode: flex; --column-align: center;">
								<section class="iw9FDOIygJyoQtUHSJAg currency-count" style="font-size: 0.18rem;">
									<div class="countTextBox" >
										<span class="SSAbrhtT3U690CrzLUd5 SSYrjrFkdeGEfKeXOPu3 count-to">
											<span class="notranslate">{{moneyInfo.balance_total_str}}</span>
										</span>
										<div  class="refresh-icon"
										 :class="{
											 animate__manimated:animate__manimated
										 }"
										 @click="animate_conter">
											<svg-icon className="refresh-icon" icon-class="style_1_icon_sx--svgSprite:all" />
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ul class="WDHlla5Ly3KnT0nfXaby">

			<li class="fzTE3kPaq_bjTxdoKUUY PIj1PfbzHvacLB3SeEjQ" 
				@click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')"
			>
				<div class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0" style="width: 0.53rem; height: 0.53rem;">
					<svg-icon className="imageNav" icon-class="style_1_icon_mid_tx--svgSprite:all" />
				</div>
				<!-- set_withdraw_password
				 
				
				
				-->
				<span class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text svnP3Mumo3rIPjndHvXk"
					style="width: 1.52rem; margin-bottom: -1.34588px; padding-bottom: 1.34588px;">
					<span class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 11.44px;">
						<span class="TMZWYZcIv5YQXVdFMVrw inner-text" style="-webkit-line-clamp: 2;">{{ $t('mine._Saque') }}</span>
					</span>
				</span>
			</li>
			<li class="fzTE3kPaq_bjTxdoKUUY PIj1PfbzHvacLB3SeEjQ" @click="$store.commit('isDeposit',true)">
				<div class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0" style="width: 0.53rem; height: 0.53rem;">
					<svg-icon className="imageNav" icon-class="style_1_icon_mid_cz--svgSprite:all" />
				</div><span class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text svnP3Mumo3rIPjndHvXk"
					style="width: 1.52rem; margin-bottom: -1.34588px; padding-bottom: 1.34588px;"><span
						class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 11.44px;"><span
							class="TMZWYZcIv5YQXVdFMVrw inner-text"
							style="-webkit-line-clamp: 2;">{{ $t('mine._Depósito') }}</span></span></span>
			</li>
			<li class="fzTE3kPaq_bjTxdoKUUY PIj1PfbzHvacLB3SeEjQ" @click="openUrl('yuebao')">
				<div class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0" style="width: 0.53rem; height: 0.53rem;">
					<svg-icon className="imageNav" icon-class="style_1_icon_mid_lxb--svgSprite:all" />
				</div><span class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text svnP3Mumo3rIPjndHvXk"
					style="width: 1.52rem; margin-bottom: -1.34588px; padding-bottom: 1.34588px;"><span
						class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 11.44px;"><span
							class="TMZWYZcIv5YQXVdFMVrw inner-text"
							style="-webkit-line-clamp: 2;">{{ $t('mine._Juros') }}</span></span></span>
			</li>
			<li class="fzTE3kPaq_bjTxdoKUUY PIj1PfbzHvacLB3SeEjQ" @click="openUrl('rechargeFund')" >
				<div class="XXWbX0RAOJw0tKNMdfjP">
					<p class="MleaLdyhud6S_QLCOFLv"> 0,00</p>
					<div class="blXLPejbalUrq5IycYVR Eejo1lVdV64NxGRLZqlB" style="width: 0.2rem; height: 0.2rem;">
						<svg-icon className="imageNav-a" icon-class="style_mid_gjj_qp--svgSprite:all" />
					</div>
				</div>
				<div class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0" style="width: 0.53rem; height: 0.53rem;">
					<svg-icon className="imageNav" icon-class="style_1_icon_mid_gjj--svgSprite:all" />
				</div><span class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text svnP3Mumo3rIPjndHvXk"
					style="width: 1.52rem; margin-bottom: -1.34588px; padding-bottom: 1.34588px;"><span
						class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 11.44px;"><span
							class="TMZWYZcIv5YQXVdFMVrw inner-text" style="-webkit-line-clamp: 2;">
							{{ $t('mine._FundodePrevidência') }}
						</span></span></span>
			</li>
			<!--  -->
		</ul>
		<div class="AefUuGAjP2_TGfXr2tb5">
			<div class="QjKbXiMuci8YHLOg1oLy R9rLcBYR0qPLI4OtSjmm" @click="openUrl('vip')">
				<div class="H3R7gDgRkHv7o8iF3mIU">
					<div class="HTOLU0TpjL6uV5Oqv4ZN">
						<div class="spAozNwsF6Hqczkk4Dh1 HK0Vodjr4XjEYKx0kzOF"
							style="background-color: rgb(36, 178, 153);"><img
								src="./../assets/icons/images/img_vip.webp"
								class="goDRiiBsuEuXD3W1NphN"><span data-text="0" class="FYBFUGagoFCn97O0fC7B">0</span>
						</div>
						<!-- 
						
						' -->
						<p class="GOBbmMp_iVYfmy09aCBe" v-if="userInfo.vip_info"><span class="XFrSsp_D2Rrmp2f4HAwr">{{ $t('mine._Restantes') }}</span>{{userInfo.vip_info.next_level_name}}<span
								class="u27MNIiWnpOLrkDikEP2">{{ $t('mine._Valorrestanteparaaposta') }}</span> {{userInfo.vip_info.upneed_bet}}</p>
					</div>
					<svg-icon className="EzRod1ZnSBOWKZYQpbwb" icon-class="comm_icon_fh--svgSprite:all" />
				</div>
				<div class="iG7k0pESTmgbL8SZ3UK6" v-if="userInfo.vip_info">
					<div class="Za5GyDtTNMgfhOKneFqa KTvamG3BLJS1RSp_lDN1"
						style="flex: 0 0 0.9rem; width: 0.9rem; height: 0.9rem; font-size: 0.327273rem;">
						<div class="MvKC9NNR4GxeVri71U2i">
							<img v-lazy="userInfo.vip_info.icon"
								class="goDRiiBsuEuXD3W1NphN" style="width: 0.9rem; height: 0.9rem;">
							<img  v-lazy="userInfo.vip_info.backgroud" 
								class="goDRiiBsuEuXD3W1NphN" style="width: 0.9rem; height: 0.9rem;"><span
								:data-text="userInfo.vip_info.level">{{  userInfo.vip_info.level}}</span>
						</div>
					</div>
					<div class="nUtoDOFYqni_cq_dHUjg">
						<div class="i9WaVhztJIXx90Q1PPLD"><span
								class="N4LsWhJBhqqyTkv6V0NE auto-shrink-text Rp9bR9P03_qFsh1PnGSc"
								style="width: 100%; margin-bottom: -1.22353px; padding-bottom: 1.22353px;"><span
									class="ke1kCzBARxq5HSxbDQ6h" style="font-size: 10.4px;"><span
										class="TMZWYZcIv5YQXVdFMVrw inner-text" style="-webkit-line-clamp: 2;">{{ $t('mine._Apostaparapromoção') }}</span></span></span>
							<div class="J5dtKM_LB6fse7jhX2hk FPKXpabqN25lBr4IA7LQ">
								<div class="MtFB4D3SHXXdqKU0qelp SC6yMRCGE2upeyEdgibm" :style="{
									width:`${(removeThousandSeparatorAndDecimalPoint(userInfo.vip_info.now_bet)/removeThousandSeparatorAndDecimalPoint(userInfo.vip_info.upwhere_bet)*100)}%`
								}"></div><span
									class="kNDhRFHNQ398GxjnCVlg u5sLSrD0KWEulumHoUzc"> {{userInfo.vip_info.now_bet}}/ {{userInfo.vip_info.upwhere_bet}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="Nhw0IwszjvREbTflQCC5 menulist-wrap">
			<ul class="WP4OJFpuaeK0siGCKAop menulist">
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('RecuperaroSaldo')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_zhye--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._RecuperaroSaldo') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('report',{current:0})">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_zhmx--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Conta') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
		

				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('report',{current:1})">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a"  style="color: var(--theme-secondary-color-success);" icon-class="style_1_icon_list_tzjl--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Apostas') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i"  icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('report',{current:2})">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_grbb--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Relatório') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i"  icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" style="color: var(--theme-secondary-color-error);" icon-class="style_1_icon_list_txgl--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._GestãoRetiradas') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i"  icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				
			</ul>
		
			<div class="_1uAN9V4K5qAvo97hsNY"></div>
			<ul class="WP4OJFpuaeK0siGCKAop menulist">
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('promote')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_tgzq--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Convidar') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<!-- sssss -->
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg"  @click="openUrl('setting')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_grzl--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Dados') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li  class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="openUrl('securityConter')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_aqzx--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Segurança') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="$store.commit('overlayShow')">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_yy--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Música') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="isShow=!isShow">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_xzyy--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							
							{{ $t('mine._Idioma') }}
						</span>
					</div>
					<!-- Idioma-popup -->
					
					<!-- Idioma-popup -->
					<div class="m1oUoLwKabgKD2uQ3ToE" >
						<p style="margin-right:.1rem;color: var(--theme-text-color-lighten);">
							{{$store.state._i18nLocale=='pt'?'Português':'English'}}
						</p>
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" 
				
				@click="$router.push({name:'notice'})"
				>
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_cjwt--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._FAQ') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<!-- <li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_yjfk--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._BônusdeSugestão') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li> -->
				<!--  @click="openUrl('device-info')"  -->
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_dlsb--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Façaloginnodispositivo') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="$router.push({name:'details',query:{
							title:$t('popup._Sobre'),
							isConter:true,
							content:$store.state.config.site_about
						}})">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_gywm--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							{{ $t('mine._Sobre') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				<li class="_nFA6Niz9YzjHuesIJKg BfzjZkte01XMmt7SD6tg" @click="Saireiniciarsess">
					<div class="h4cR2qROZGaQg92ZpG2y">
						<div class="blXLPejbalUrq5IycYVR JYYL9fMHbqMw83UNBCCw" style="width: 0.4rem; height: 0.4rem;">
							<svg-icon className="imageNav-a" icon-class="style_1_icon_list_aqtc--svgSprite:all" />
						</div>
						<span class="_30PUwQvVXtVh5D7EL9w mine-menulist-label">
							 
							{{ $t('mine._Sair') }}
						</span>
					</div>
					<div class="m1oUoLwKabgKD2uQ3ToE">
						<svg-icon className="i" icon-class="comm_icon_fh--svgSprite:all" />
					</div>
				</li>
				
				
			</ul>
		</div>
		<APopup :isShow="isShow" @_remove="_remove"></APopup>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { Dialog } from 'vant';
	import APopup from "@/components/Idioma-popup/index.vue";
	
	export default {
		name: 'records',
		computed:{
			...mapState(['popList','userInfo','moneyInfo'])
		},
		components: {
			APopup
		},
		data() {
			return {
				coloImgactivity: require('./../assets/icons/images/style_1_topbg_yd.webp'),
				isShow:false,
				animate__manimated:false,
			}
		},
		created() {
		},
		methods: {
			animate_conter(e){//
				this.animate__manimated=true;
				this.getBalance()
				let time=setTimeout(()=>{
					clearTimeout(time)
					this.animate__manimated=false;
				},300)
			},
			Saireiniciarsess(){
				let _this=this;
				Dialog.confirm({
				  title: this.$t('mine._Lembrete'),
				  message: this.$t('mine._Desejaterminarsessãodaconta'),
				  confirmButtonText:this.$t('mine._Cancelar'),
				  cancelButtonText:this.$t('mine._Confirmarsaída'),
				}).then(() => {
				  })
				  .catch(() => {
					_this.$router.push({name:'home'});
					localStorage.removeItem('lifeData');
					setTimeout(()=>{
						location.reload();
					})
				  });
			},
			_remove(){//
				this.isShow=false
			},
			tabFun(e) {
				this.$store.commit('active', e)
			},
			stickyScroLL(e) {}
		}
	}
</script>
<style scoped>
	.Nhw0IwszjvREbTflQCC5 {
		font-size: .24rem
	}

	.Nhw0IwszjvREbTflQCC5 ._1uAN9V4K5qAvo97hsNY {
		background-color: var(--theme-bg-color);
		height: .2rem;
		width: 100
	}

	.Nhw0IwszjvREbTflQCC5 ._1uAN9V4K5qAvo97hsNY {
		background-color: var(--theme-bg-color);
		height: .2rem;
		width: 100
	}

	.Nhw0IwszjvREbTflQCC5 .rebPmbNdLSySZKEqLMYm {
		background-color: var(--theme-main-bg-color)
	}

	.Nhw0IwszjvREbTflQCC5 .BfzjZkte01XMmt7SD6tg {
		color: var(--theme-primary-color);
		padding: .3rem .44rem
	}

	.Nhw0IwszjvREbTflQCC5 .BfzjZkte01XMmt7SD6tg .mine-menulist-label {
		color: var(--theme-text-color-darken)
	}

	.Nhw0IwszjvREbTflQCC5 .BfzjZkte01XMmt7SD6tg .promoteRight>i {
		display: none !important
	}

	.Nhw0IwszjvREbTflQCC5 .BfzjZkte01XMmt7SD6tg .promoteRight p {
		height: .28rem
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .h4cR2qROZGaQg92ZpG2y,
	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .m1oUoLwKabgKD2uQ3ToE {
		-ms-flex-negative: 0;
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		flex-shrink: 0
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg ._30PUwQvVXtVh5D7EL9w {
		margin-left: .24rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .m1oUoLwKabgKD2uQ3ToE {
		-ms-flex-pack: end;
		justify-content: end
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .m1oUoLwKabgKD2uQ3ToE .eftT5wc3_J0TkWpZUbSf {
		margin-left: .1rem
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .m1oUoLwKabgKD2uQ3ToE .o7KUVQuKEMNzxFB1eGIA {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		overflow: hidden;
		text-align: right;
		width: 2.6rem
	}

	.m1oUoLwKabgKD2uQ3ToE .i {
		color: var(--theme-color-line);
		height: .24rem;
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		width: .24rem
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .h4cR2qROZGaQg92ZpG2y {
		-ms-flex: 1;
		flex: 1;
		overflow: hidden
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .h4cR2qROZGaQg92ZpG2y .JYYL9fMHbqMw83UNBCCw {
		-ms-flex-negative: 0;
		flex-shrink: 0
	}

	.WP4OJFpuaeK0siGCKAop ._nFA6Niz9YzjHuesIJKg .h4cR2qROZGaQg92ZpG2y.SZo2b82I9zuoWxRiuvOg {
		width: 2.6rem
	}

	.WDHlla5Ly3KnT0nfXaby {
		-ms-flex-pack: distribute;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-around;
		margin-bottom: .24rem;
		margin-top: .32rem
	}

	.WDHlla5Ly3KnT0nfXaby .fzTE3kPaq_bjTxdoKUUY {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		-ms-flex-direction: column;
		flex-direction: column;
		font-size: .22rem;
		position: relative;
		text-align: center
	}

	.WDHlla5Ly3KnT0nfXaby .fzTE3kPaq_bjTxdoKUUY .svnP3Mumo3rIPjndHvXk {
		margin-top: .08rem
	}

	.mine_conten {
		min-height: 100vh;
		background-repeat: no-repeat;
		background-color: var(--theme-main-bg-color);
	}

	.CToeIaCnZ7Jr3D1XKTxw {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: .1rem .24rem;
		position: relative
	}

	.CToeIaCnZ7Jr3D1XKTxw .DiNiMDlhVGqZnjLoStS4 {
		-ms-flex-pack: end;
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		font-weight: 200;
		justify-content: flex-end
	}

	.CToeIaCnZ7Jr3D1XKTxw .DiNiMDlhVGqZnjLoStS4 .So_waOZgg7qEl0wURIQz {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.CToeIaCnZ7Jr3D1XKTxw .DiNiMDlhVGqZnjLoStS4 .So_waOZgg7qEl0wURIQz .header-item-label {
		display: block;
		height: .5rem;
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: .9rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .DiNiMDlhVGqZnjLoStS4 .So_waOZgg7qEl0wURIQz .imageWrap {
		color: var(--theme-primary-color);
		font-size: .4rem;
		width: .4rem;
		height: .4rem;
	}

	.CToeIaCnZ7Jr3D1XKTxw .SVLWJ4WJI54O4lWBc7yk {
		display: -ms-flexbox;
		display: flex
	}

	.wJ30VxmrZK3Y5gTd1Zq0 {
		color: var(--theme-mine-icon-color)
	}

	.wJ30VxmrZK3Y5gTd1Zq0 .imageWrap {
		width: 0.28rem;
		height: 0.28rem;
	}

	.wJ30VxmrZK3Y5gTd1Zq0 .imageNav {
		width: 0.53rem;
		height: 0.53rem;
	}

	.VOgj9FBCnkozjr2NA7rl .refresh-icon {
		color: var(--theme-mine-icon-color) !important;
		font-size: .28rem;
		width: 0.28rem;
		hheight: 0.28rem;
	}

	.vy918omw_N7rBkJ235XX .iw9FDOIygJyoQtUHSJAg>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .18rem
	}

	.glbY5bkp9x7KTYOGrdBC {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center
	}

	.CToeIaCnZ7Jr3D1XKTxw .zvrVqQuEWSzu7Nsavegl {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: center
	}

	.CToeIaCnZ7Jr3D1XKTxw .P_VF1DJcPs_q4ieRYz7n {
		border-radius: 50%;
		margin-right: .1rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .NeL_d0h5i7Kr9xdDCTty {
		font-size: .24rem;
		margin-bottom: .09rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .EpCtJEz_E_WMlMB6HeaI {
		margin-right: .1rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .32rem
	}

	.B8Z5jNPFB44UUahGOFBH {
		color: var(--theme-text-color)
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .kot86ytTGgQsl2K_lpQ5 {
		margin-left: .1rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .DnC6T98QN772WLPs1ooC {
		margin: 0 .1rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .DnC6T98QN772WLPs1ooC .HiI_svzoqAjA6nNwGufG {
		color: var(--theme-text-color);
		font-size: .2rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .DnC6T98QN772WLPs1ooC .UNEMFCOoFUG1wM5qb0v8 span {
		-ms-flex-align: center;
		align-items: center;
		display: inline-block;
		display: -ms-flexbox;
		display: flex;
		max-width: 2.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .VOgj9FBCnkozjr2NA7rl {
		border: none;
		position: inherit !important
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .VOgj9FBCnkozjr2NA7rl .count-to {
		border-color: var(--theme-text-color-darken) !important;
		color: var(--theme-text-color-darken) !important;
		display: inline-block;
		font-size: .32rem !important;
		margin: 0 .2rem 0 .1rem !important;
		max-width: 2.5rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .VOgj9FBCnkozjr2NA7rl .refresh-icon {
		color: var(--theme-mine-icon-color) !important;
		font-size: .28rem
	}

	.CToeIaCnZ7Jr3D1XKTxw .Fn6L7w5ZEXiwo2fTNrRn .VOgj9FBCnkozjr2NA7rl .currency-triangle {
		-webkit-transform: translate(-50%, .01rem) rotate(45deg);
		transform: translate(-50%, .01rem) rotate(45deg)
	}

	.CToeIaCnZ7Jr3D1XKTxw .DTiaFYH2mnNOHXa8KLF0 {
		background: var(--theme-text-color-darken);
		display: block;
		height: .26rem;
		margin: 0 .16rem;
		opacity: .1;
		width: .01rem
	}

	.PIj1PfbzHvacLB3SeEjQ {
		color: var(--theme-text-color-darken)
	}

	.XXWbX0RAOJw0tKNMdfjP {
		height: .28rem;
		top: -.05rem
	}

	.XXWbX0RAOJw0tKNMdfjP,
	.XXWbX0RAOJw0tKNMdfjP .Eejo1lVdV64NxGRLZqlB {
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%)
	}

	.Eejo1lVdV64NxGRLZqlB .imageNav-a {
		width: .2rem;
		height: .2rem
	}

	.XXWbX0RAOJw0tKNMdfjP .Eejo1lVdV64NxGRLZqlB {
		bottom: -.06rem;
		color: var(--theme-label-secondary-color-finance)
	}

	.XXWbX0RAOJw0tKNMdfjP .MleaLdyhud6S_QLCOFLv {
		background: var(--theme-label-secondary-color-finance);
		border-radius: .125rem;
		color: var(--theme-secondary-color-help-text-color);
		font-size: .2rem;
		height: .23rem;
		line-height: .23rem;
		min-width: .7rem;
		padding: 0 .04rem;
		text-align: center
	}

	.AefUuGAjP2_TGfXr2tb5 {
		padding: 0 .24rem;
		position: relative;
		z-index: 2
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy {
		background-color: var(--theme-primary-color);
		border-radius: .16rem;
		color: var(--theme-primary-font-color);
		padding: .19rem .24rem;
		box-sizing: border-box;
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy.R9rLcBYR0qPLI4OtSjmm {
		-webkit-box-shadow: 0 .1rem .24rem rgbc(0, 0, 0, .1);
		box-shadow: 0 .1rem .24rem rgbc(0, 0, 0, .1)
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy.y17nf7q7Qw17p9wtNg71 {
		-webkit-box-shadow: 0 .1rem .24rem #c5d4e9;
		box-shadow: 0 .1rem .24rem #c5d4e9
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .EzRod1ZnSBOWKZYQpbwb {
		opacity: .27;
		fons-size: .14rem;
		width: .24rem;
		height: .24rem;
		transform: rotate(180deg);
	}

	.J5dtKM_LB6fse7jhX2hk {
		overflow: hidden;
		position: relative;
		width: 100%
	}

	.J5dtKM_LB6fse7jhX2hk.QF3VjOqeGp_QDYsZS4Ei {
		overflow: visible;
		text-align: center
	}

	.J5dtKM_LB6fse7jhX2hk .MtFB4D3SHXXdqKU0qelp {
		height: 100%;
		left: 0;
		position: absolute
	}

	.J5dtKM_LB6fse7jhX2hk .kNDhRFHNQ398GxjnCVlg {
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%
	}

	.J5dtKM_LB6fse7jhX2hk .YumY578c3s4HnIJXRiBw i {
		color: var(--theme-primary-font-color);
		font-size: .22rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .EzRod1ZnSBOWKZYQpbwb,
	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .EzRod1ZnSBOWKZYQpbwb svg {
		color: var(--theme-primary-font-color)
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		border-bottom: .01rem solid rgba(0, 0, 0, .1);
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		padding-bottom: .2rem;
		width: 100%
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HTOLU0TpjL6uV5Oqv4ZN {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .24rem;
		line-height: 1
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HTOLU0TpjL6uV5Oqv4ZN .GOBbmMp_iVYfmy09aCBe {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HTOLU0TpjL6uV5Oqv4ZN .GOBbmMp_iVYfmy09aCBe span {
		opacity: .6
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HTOLU0TpjL6uV5Oqv4ZN .GOBbmMp_iVYfmy09aCBe span.XFrSsp_D2Rrmp2f4HAwr {
		margin: 0 .1rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HTOLU0TpjL6uV5Oqv4ZN .GOBbmMp_iVYfmy09aCBe span.u27MNIiWnpOLrkDikEP2 {
		margin: 0 .05rem 0 .1rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HK0Vodjr4XjEYKx0kzOF {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border-radius: .14rem 0 .14rem 0;
		color: #e5b952;
		display: -ms-inline-flexbox;
		display: inline-flex;
		font-size: .18rem;
		font-weight: 700;
		height: .32rem;
		justify-content: center;
		margin-right: .2rem;
		width: .77rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HK0Vodjr4XjEYKx0kzOF img {
		height: .15rem;
		width: .32rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .H3R7gDgRkHv7o8iF3mIU .HK0Vodjr4XjEYKx0kzOF .vip-badge-bgicon svg {
		height: .32rem;
		width: .77rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 {
		-ms-flex-align: center;
		align-items: center;
		border-top: .01rem solid hsla(0, 0%, 100%, .2);
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		padding-top: .2rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .Rp9bR9P03_qFsh1PnGSc {
		max-width: 1.5rem;
		opacity: .5
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .Rp9bR9P03_qFsh1PnGSc .inner-text {
		text-align: left
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .nUtoDOFYqni_cq_dHUjg {
		grid-gap: .1rem;
		-ms-flex-align: center;
		align-items: center;
		display: grid;
		-ms-flex: 1;
		flex: 1;
		font-size: .2rem;
		gap: .1rem;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		margin-left: .2rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .nUtoDOFYqni_cq_dHUjg .FPKXpabqN25lBr4IA7LQ {
		background-color: rgba(0, 0, 0, .08);
		border-bottom: .01rem solid hsla(0, 0%, 100%, .5);
		border-radius: .1rem;
		height: .2rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .nUtoDOFYqni_cq_dHUjg .SC6yMRCGE2upeyEdgibm {
		background-color: var(--theme-secondary-color-success);
		border-radius: .1rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .nUtoDOFYqni_cq_dHUjg .u5sLSrD0KWEulumHoUzc {
		color: #fff;
		line-height: .2rem
	}

	.AefUuGAjP2_TGfXr2tb5 .QjKbXiMuci8YHLOg1oLy .iG7k0pESTmgbL8SZ3UK6 .nUtoDOFYqni_cq_dHUjg .i9WaVhztJIXx90Q1PPLD {
		display: grid;
		-ms-flex: 1;
		flex: 1;
		grid-template-columns: auto 1fr
	}

	.AefUuGAjP2_TGfXr2tb5 .eHn3QEX_ym94TibdinwJ {
		margin: 0 .4rem 0 .1rem
	}

	.AefUuGAjP2_TGfXr2tb5 .eHn3QEX_ym94TibdinwJ i {
		color: var(--theme-primary-font-color)
	}

	.KTvamG3BLJS1RSp_lDN1,
	.Za5GyDtTNMgfhOKneFqa,
	.eAZ0IOWR0JI3ZfRAp3IF {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		margin: auto;
		position: relative;
		text-align: center
	}

	.KTvamG3BLJS1RSp_lDN1 img,
	.Za5GyDtTNMgfhOKneFqa img,
	.eAZ0IOWR0JI3ZfRAp3IF img {
		position: absolute
	}

	.KTvamG3BLJS1RSp_lDN1 span,
	.Za5GyDtTNMgfhOKneFqa span,
	.eAZ0IOWR0JI3ZfRAp3IF span {
		color: transparent;
		display: inline-block;
		font-style: normal;
		font-weight: 700;
		position: relative;
		text-shadow: 0 .01rem 0 rgba(0, 0, 0, .4)
	}

	.KTvamG3BLJS1RSp_lDN1 span:before,
	.eAZ0IOWR0JI3ZfRAp3IF span:before {
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#f7ea94), color-stop(51%, #e5b952), to(#ce9510));
		background-image: linear-gradient(180deg, #f7ea94 0, #e5b952 51%, #ce9510);
		background-size: cover;
		content: attr(data-text);
		height: 100%;
		left: 0;
		position: absolute;
		text-shadow: none;
		top: 0;
		width: 100%;
		z-index: 0
	}

	.MvKC9NNR4GxeVri71U2i {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center
	}
</style>
