import { render, staticRenderFns } from "./CadastrarSubordinados.vue?vue&type=template&id=27ff5262&scoped=true"
import script from "./CadastrarSubordinados.vue?vue&type=script&lang=js"
export * from "./CadastrarSubordinados.vue?vue&type=script&lang=js"
import style0 from "./CadastrarSubordinados.vue?vue&type=style&index=0&id=27ff5262&prod&lang=css"
import style1 from "./CadastrarSubordinados.vue?vue&type=style&index=1&id=27ff5262&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ff5262",
  null
  
)

export default component.exports