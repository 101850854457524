<template>
	<div class="maxMaxth evebt promote">
		<!--  -->
		<van-nav-bar
		  title="Histórico"
		  left-arrow
		  fixed
		  placeholder
		  @click-left="onClickLeft"
		/>
		<div class="cRKA8AT8lf2bItsMSztA">
			<div class="a7neOLNNGzlPwu32tGO4">
				<div class="v0FZl0YCkbZ5KOXk68M4 van-dropdown-menu-box">
					<!-- <van-dropdown-menu>
						<van-dropdown-item v-model="value1" :options="option1" />
					</van-dropdown-menu> -->
					<ant-select style="margin-left: .2rem;" @change="BonusLogfun" :value="value1" :options='option1'
						>
					</ant-select>
				</div>
				<div class="RnNfgaFEYzhMdHzQIun2">
					<span>Comissão recebida</span>
					<span class="XGEOwGXgDL9a2SebPqvj">{{tabConte.total}}</span>
				</div>
			</div>
		</div>
		<div class="d79TWyHPK19kGdhyVfnQ">
			<ul class="ant-list-items " v-for="(item,index) in tabConte.list"  :key="index">
				<div class="">
					<van-row type="flex">
						<van-col span="16">
							<span>{{item.title}}</span>
							<!-- <span><span class="currencyAmount"> 9</span></span> -->
						</van-col>
						<van-col span="8">
							<span>Quantia</span>
							<span><span class="currencyAmount yellowColor">{{item.money}}</span></span>
						</van-col>
						<van-col span="16">
							<span class="dateTime">{{item.create_time}}</span>
						</van-col>
					</van-row>
				</div>
			</ul>
			
		</div>
		
		<van-empty image-size="2.1rem" v-if="!tabConte.list || !tabConte.list.length" class="custom-image"
			:image="img_none_jl"
			:description="$t('description')" />
	</div>
</template>

<script>
	import {
		domFunCtina,
		conteNameValue,
	} from "@/utils/tim.js"
	// 
import {
		shareBonusLog
	} from "@/apis/modules/share.js";
	export default {
		name: 'canReceive',
		components: {
		
		},
		data() {
			return {
				value1: conteNameValue,
				option1: domFunCtina,
				forData:{
					time:conteNameValue,
				},
				tabConte:{},
			}
		},
		mounted() {
			this.shareBonusLog()
		},
		methods: {
			BonusLogfun(e){
				this.value1=e[0];
				this.shareBonusLog();
			},
			shareBonusLog(){
				shareBonusLog({
					...this.forData,
					time:this.value1
				}).then(({code,data,msg})=>{
					this.tabConte=data;
				})
			},
			// this.value1=e[0]
			tabFun(e) {
				this.$store.commit('active', e)
			},
			onClickLeft(){
					 this.$router.go(-1);
			},
			stickyScroLL(e) {}
		}
	}
</script>
<style>

</style>

<style scoped>
	.v0FZl0YCkbZ5KOXk68M4 {
		display: flex;

	}
	.d79TWyHPK19kGdhyVfnQ{
		padding: .2rem;
	}
	 .d79TWyHPK19kGdhyVfnQ .ant-col span:first-child {
	    margin-right: .1rem
	}
	.cRKA8AT8lf2bItsMSztA {
		height: .9rem;
		padding: 0 .2rem;
	}

	.a7neOLNNGzlPwu32tGO4 {
		-ms-flex-align: center;
		-ms-flex-pack: end;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		height: .5rem;
		height: .9rem;
		justify-content: space-between;
	}

	.cRKA8AT8lf2bItsMSztA {
		display: -ms-flexbox;
		font-size: .24rem;
		line-height: 1.5;
		color: var(--theme-text-color-lighten);
	}

	.XGEOwGXgDL9a2SebPqvj {
		color: var(--theme-secondary-color-finance)
	}
	.ant-list-items {
	    color: var(--theme-text-color-lighten);
		font-size: .25rem;
		margin-bottom: .2rem;
	}
	.ant-list-items>div,.ant-list-items>li {
	    border-radius: .1rem;
	    padding: .22rem .2rem
	}
	.ant-list-items>:nth-child(2n) {
	    background-color: var(--theme-bg-color)
	}
	.ant-list-items>:nth-child(2n),.ant-list-items>:nth-child(odd) {
	    background-color: var(--theme-main-bg-color)
	}
	.yellowColor{
		    color: var(--theme-secondary-color-finance) !important;
	}
	.dateTime{
		padding-top: .1rem;
		margin-top: .1rem;
	}

</style>
